// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Procesos__Procesos__2i2CJ{\r\n    display: flex; \r\n    justify-content: space-around;\r\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Procesos": "Procesos__Procesos__2i2CJ"
};
export default ___CSS_LOADER_EXPORT___;
