import React, { Suspense, useEffect } from "react";
import {
  Route,
  Switch,
  Redirect,
  withRouter,
  useLocation,
} from "react-router-dom";
import { connect } from "react-redux";

import * as actions from "./store/actions";

import SimpleBackdrop from "./components/UI/Backdrop/Backdrop";
import Logout from "./containers/Auth/Logout/Logout";
import Procesos from "./containers/Procesos/Procesos";

import IframePortal from "./containers/Auth/IframePortal/IframePortal";
import Loading from "./components/UI/Loading/Loading";

const Layout = React.lazy(() => {
  return import("./containers/Layout/Layout");
});
const PantallaCarga = React.lazy(() => {
  return import("./containers/PantallaCarga/PantallaCarga");
});

const App = (props) => {
  const {
    onTryAuthSignin,
    login,
    token,
    loadingAuth,
    loadingCambiarEmpresa,
    loadingCambiarMoneda,
    loadingExecuteCambiarAjustaPorInflacion,
  } = props;

  const { pathname, search } = useLocation();

  const getDefaultPath = () => {
    return "/procesos";
  };

  useEffect(() => {
    onTryAuthSignin();
  }, [onTryAuthSignin]);

  let routes = (
    <Switch>
      <Route path="/" component={PantallaCarga} />
    </Switch>
  );

  if (token !== null && !loadingAuth) {
    routes = (
      <Switch>
        <Route exact path="/logout" component={Logout} />
        <Route path="/procesos" render={(props) => <Procesos {...props} />} />
        {props.token !== null ? (
          <Redirect push to={getDefaultPath()} />
        ) : (
          <Login />
        )}
      </Switch>
    );
  }

  return (
    <div>
      <Suspense fallback={<Loading />}>
        <Layout>
          <SimpleBackdrop
            open={
              loadingCambiarEmpresa ||
              loadingCambiarMoneda ||
              loadingExecuteCambiarAjustaPorInflacion
            }
          />
          {routes}
        </Layout>
        <IframePortal />
      </Suspense>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    loadingAuth: state.auth.loading,
    loadingCambiarEmpresa: state.multiempresa.loadingCambiarEmpresa,
    loadingCambiarMoneda: state.moneda.loadingCambiarMoneda,
    loadingExecuteCambiarAjustaPorInflacion:
      state.configuraciones.executeCambiarAjustaPorInflacion,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onTryAuthSignin: () => dispatch(actions.authCheckState()),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
