import * as actionTypes from "./actionTypes";
import axios from "axios";
import { baseUrlDashboard } from "../../assets/shared/urlApps";
import {
  buildBearerToken,
  storageEmpresaId,
} from "../../assets/shared/sessionData";
import idIndicadores from "../../assets/shared/idIndicadores";

export const seleccionarMoneda = (idMoneda, idEmpresa = storageEmpresaId()) => {
  return (dispatch) => {
    dispatch(executeCambiarMoneda(true));
    const authStr = "Bearer ".concat(sessionStorage.getItem("token"));
    const baseUrl = baseUrlDashboard();

    const data = [
      { idMoneda: "PES", nombre: "Pesos", signo: "$" },
      { idMoneda: "DOL", nombre: "Dólar", signo: "US$" },
    ];
    dispatch(loadMonedasSuccess(data));

    // axios
    //   .get(
    //     `${baseUrl}monedas/cambiarMoneda?monedaId=${idMoneda}&empresaId=${idEmpresa}&indicadorId=${idIndicadores.cambiarMoneda}`,
    //     {
    //       headers: {
    //         Authorization: authStr,
    //         Accept: "application/json",
    //         "Content-Type": "application/json",
    //       },
    //     }
    //   )
    //   .then((response) => {
    //     window.location.reload();
    //   })
    //   .catch((error) => {
    //     executeCambiarMoneda(false);
    //   });
  };
};

const executeCambiarMoneda = (loadingCambiarMoneda) => {
  return {
    type: actionTypes.EXECUTE_CAMBIAR_MONEDA,
    loadingCambiarMoneda,
  };
};

export const loadMonedas = (idEmpresa = storageEmpresaId()) => {
  return (dispatch) => {
    dispatch(loadMonedasStart());

    const authStr = buildBearerToken();
    const data = [
      { idMoneda: "PES", nombre: "Pesos", signo: "$" },
      { idMoneda: "DOL", nombre: "Dólar", signo: "US$" },
    ];

    dispatch(loadMonedasSuccess(data));
    // axios
    //   .get(
    //     `${baseUrlDashboard()}monedas/monedas?idEmpresa=${idEmpresa}&indicadorId=${
    //       idIndicadores.monedas
    //     }`,
    //     {
    //       headers: {
    //         Authorization: authStr,
    //         Accept: "application/json",
    //         "Content-Type": "application/json",
    //       },
    //     }
    //   )
    //   .then((response) => {
    //     dispatch(loadMonedasSuccess(response.data));
    //   })
    //   .catch((error) => {
    //     if (error.response) {
    //       if (error.response.statusCode === 500) {
    //         dispatch(loadMonedasFail(error));
    //       } else {
    //         dispatch(loadMonedas(idEmpresa));
    //       }
    //     } else {
    //       dispatch(loadMonedasFail(error));
    //     }
    //   });
  };
};

const loadMonedasStart = () => {
  return {
    type: actionTypes.LOAD_MONEDAS_START,
  };
};

const loadMonedasSuccess = (monedas) => {
  return {
    type: actionTypes.LOAD_MONEDAS_SUCCESS,
    monedas,
  };
};

const loadMonedasFail = (error) => {
  return {
    type: actionTypes.LOAD_MONEDAS_FAIL,
    error,
  };
};
