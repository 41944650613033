// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Tabs__TabsContainer__zUG8j {\r\n  background: #ffffff 0% 0% no-repeat padding-box;\r\n  border-radius: 3px;\r\n  justify-content: center;\r\n}\r\n\r\n.Tabs__TabsScroller__1HnTP {\r\n  display: flex !important;\r\n  justify-content: center;\r\n  margin-bottom: 10px;\r\n}\r\n\r\n.Tabs__TabsIndicator__3ent_ {\r\n  height: 3px !important;\r\n  border-top-right-radius: 5px;\r\n  border-top-left-radius: 5px;\r\n  background-color: #F58434 !important;\r\n}\r\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TabsContainer": "Tabs__TabsContainer__zUG8j",
	"TabsScroller": "Tabs__TabsScroller__1HnTP",
	"TabsIndicator": "Tabs__TabsIndicator__3ent_"
};
export default ___CSS_LOADER_EXPORT___;
