export const storageFechaFija = () => {
  return sessionStorage.getItem("fechaFija");
};

export const storageToken = () => {
  return sessionStorage.getItem("token");
};

export const storageExpirationDate = () => {
  return sessionStorage.getItem("expirationDate");
};

export const storageMultiempresa = () => {
  return sessionStorage.getItem("multiempresa");
};

export const storageEmpresaId = () => {
  return parseInt(sessionStorage.getItem("empresaId"), 10);
};

export const storageUserEmail = () => {
  return sessionStorage.getItem("userEmail");
};

export const storageMonedaId = () => {
  return sessionStorage.getItem("monedaId");
};

export const storageMonedaSigno = () => {
  return sessionStorage.getItem("monedaSigno");
};

export const buildBearerToken = () => {
  return `Bearer ${sessionStorage.getItem("token")}`;
};

export const storageClienteId = () => {
  return sessionStorage.getItem("idCliente");
};

export const storageProveedorId = () => {
  return sessionStorage.getItem("idProveedor");
};

export const storageEmpresaNombre = () => {
  return sessionStorage.getItem("empresaNombre");
};

export const storageSeccionNombre = () => {
  return sessionStorage.getItem("seccionNombre");
};
