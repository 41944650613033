import * as actionTypes from "./actionTypes";
import axios from "../../axios-dashboard";
import idIndicadores from "../../assets/shared/idIndicadores";
import {
  storageMonedaId,
  storageEmpresaId,
} from "../../assets/shared/sessionData";

export const loadClienteById = (idCliente, success) => {
  return (dispatch) => {
    dispatch(loadClienteByIdStart());

    axiosVendedores
      .get(
        `/clientes/clienteInfoMedia?idCliente=${idCliente}&indicadorId=${
          idIndicadores.clienteInfoMedia
        }&idEmpresa=${storageEmpresaId()}`
      )
      .then((response) => {
        sessionStorage.setItem("idCliente", idCliente);
        dispatch(loadClienteByIdSucces(response.data));
        return success(Promise.resolve);
      })
      .catch((error) => {
        if (
          error.response &&
          (error.response.status === 500 || error.response.status === 404)
        ) {
          dispatch(loadClienteByIdFail(error));
        } else {
          dispatch(loadClienteById(idCliente, success));
        }
      });
  };
};

const loadClienteByIdStart = () => {
  return {
    type: actionTypes.LOAD_CLIENTE_BY_ID_START,
  };
};

const loadClienteByIdSucces = (cliente) => {
  return {
    type: actionTypes.LOAD_CLIENTE_BY_ID_SUCCESS,
    cliente,
  };
};

const loadClienteByIdFail = (error) => {
  return {
    type: actionTypes.LOAD_CLIENTE_BY_ID_FAIL,
    error,
  };
};

export const unmountClienteById = () => {
  return {
    type: actionTypes.UNMOUNT_CLIENTE_BY_ID,
  };
};

//#region Load Evolucion de Gastos del Mes
export const loadEvolucionDeGastosMensual = (idCliente) => {
  return (dispatch) => {
    dispatch(loadEvolucionDeGastosMensualStart());

    axiosDashboard
      .get(
        `/ventas/VentasPorMes?idCliente=${idCliente}&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.evolucionGastosMesual
        }`
      )
      .then((response) => {
        let formatVentasParaTabla = [];
        let totalVentas = 0;
        response.data.map((venta) => {
          if (!formatVentasParaTabla.hasOwnProperty(venta.anio)) {
            formatVentasParaTabla[venta.anio] = { anio: venta.anio, meses: [] };
          }
          formatVentasParaTabla[venta.anio].meses.push({
            mes: venta.mes,
            monto: venta.monto,
          });
          return (totalVentas += venta.monto);
        });

        dispatch(
          loadEvolucionDeGastosMensualSuccess(
            response.data,
            formatVentasParaTabla,
            totalVentas
          )
        );
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.statusCode === 500)
            dispatch(loadEvolucionDeGastosMensualFail());
          else dispatch(loadEvolucionDeGastosMensual(idCliente));
        } else {
          dispatch(loadEvolucionDeGastosMensualFail());
        }
      });
  };
};

export const clearEvolucionDeGastosMensual = () => {
  return {
    type: actionTypes.CLEAR_EVOLUCION_DE_GASTOS,
  };
};

const loadEvolucionDeGastosMensualStart = () => {
  return {
    type: actionTypes.LOAD_EVOLUCION_DE_GASTOS_START,
  };
};

const loadEvolucionDeGastosMensualSuccess = (
  ventas,
  ventasDataTable,
  totalVentas
) => {
  return {
    type: actionTypes.LOAD_EVOLUCION_DE_GASTOS_SUCCESS,
    ventas,
    ventasDataTable,
    totalVentas,
  };
};

const loadEvolucionDeGastosMensualFail = (error) => {
  return {
    type: actionTypes.LOAD_EVOLUCION_DE_GASTOS_FAIL,
    error,
  };
};
//#endregion

//#region Ultima Compra
export const loadUltimaCompraByCliente = (idCliente) => {
  return (dispatch) => {
    dispatch(loadUltimaCompraByClienteStart());

    axiosVendedores
      .get(
        `/clientes/ultimaCompra?idCliente=${idCliente}&indicadorId=${
          idIndicadores.ultimaCompra
        }&empresaId=${storageEmpresaId()}`
      )
      .then((response) => {
        if (response.status === 204)
          dispatch(loadUltimaCompraByClienteFail(response));

        const { fecha, id_Comproba, nombre, referencia, total } = response.data;
        dispatch(
          loadUltimaCompraByClienteSuccess(
            fecha,
            id_Comproba,
            nombre,
            referencia,
            total
          )
        );
      })
      .catch((error) => {
        if (
          error.response &&
          (error.response.status === 500 || error.response.status === 404)
        ) {
          dispatch(loadUltimaCompraByClienteFail(error));
        } else {
          dispatch(loadUltimaCompraByCliente(idCliente));
        }
      });
  };
};

export const clearUltimaCompraByCliente = () => {
  return {
    type: actionTypes.CLEAR_ULTIMA_COMPRA_BY_CLIENTE,
  };
};

const loadUltimaCompraByClienteStart = () => {
  return {
    type: actionTypes.LOAD_ULTIMA_COMPRA_BY_CLIENTE_START,
  };
};

const loadUltimaCompraByClienteSuccess = (
  fecha,
  idComproba,
  nombre,
  referencia,
  total
) => {
  return {
    type: actionTypes.LOAD_ULTIMA_COMPRA_BY_CLIENTE_SUCCESS,
    fecha,
    idComproba,
    nombre,
    referencia,
    total,
  };
};

const loadUltimaCompraByClienteFail = (error) => {
  return {
    type: actionTypes.LOAD_ULTIMA_COMPRA_BY_CLIENTE_FAIL,
    error,
  };
};

//#endregion

//#region Cuenta Corriente
export const loadCuentaCorrienteCliente = (idCliente) => {
  return (dispatch) => {
    dispatch(loadCuentaCorrienteClienteStart());

    axiosDashboard
      .get(
        `/clientes/CuentaCorriente?idMoneda=${storageMonedaId()}&idCliente=${idCliente}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.cuentaCorrienteCliente
        }`
      )
      .then((response) => {
        dispatch(loadCuentaCorrienteClienteSuccess(response.data));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 500 || error.response.status === 404) {
            dispatch(loadCuentaCorrienteClienteFail(error));
          } else {
            dispatch(loadCuentaCorrienteCliente(idCliente));
          }
        } else {
          dispatch(loadCuentaCorrienteClienteFail(error));
        }
      });
  };
};

export const clearCuentaCorrienteCliente = () => {
  return {
    type: actionTypes.CLEAR_CUENTA_CORRIENTE_CLIENTE,
  };
};

const loadCuentaCorrienteClienteStart = () => {
  return {
    type: actionTypes.LOAD_CUENTA_CORRIENTE_CLIENTE_START,
  };
};

const loadCuentaCorrienteClienteSuccess = (comprobantes) => {
  return {
    type: actionTypes.LOAD_CUENTA_CORRIENTE_CLIENTE_SUCCESS,
    comprobantes,
  };
};

const loadCuentaCorrienteClienteFail = (error) => {
  return {
    type: actionTypes.LOAD_CUENTA_CORRIENTE_CLIENTE_FAIL,
    error,
  };
};
//#endregion

//#region Lista de clientes activos
export const loadClientes = () => {
  return (dispatch) => {
    dispatch(loadClientesStart());
    const authStr = "Bearer ".concat(sessionStorage.getItem("token"));
    axios
      .get(`/clientes`, {
        headers: { Authorization: authStr },
      })
      .then((response) => {
        const data = [
          { id: "TD", nombre: "TODOS" },
          ...response.data.map((cliente) => ({
            id: cliente.idCliente,
            nombre: cliente.razonSocial,
          })),
        ];
        dispatch(loadClientesSuccess(data));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.statusCode === 500) {
            dispatch(loadClientesFail(error));
          } else {
            dispatch(loadClientes());
          }
        } else {
          dispatch(loadClientesFail(error));
        }
      });
  };
};

const loadClientesStart = () => {
  return {
    type: actionTypes.LOAD_CLIENTES_START,
  };
};

const loadClientesSuccess = (clientes) => {
  return {
    type: actionTypes.LOAD_CLIENTES_SUCCESS,
    clientes,
  };
};

const loadClientesFail = (error) => {
  return {
    type: actionTypes.LOAD_CLIENTES_FAIL,
    error,
  };
};
//#endregion
