import thunk from "redux-thunk";
import { createStore, combineReducers, applyMiddleware, compose } from "redux";

import ventasReducer from "./store/reducers/ventas";
import filtrosReducer from "./store/reducers/filtros";
import sucursalesReducer from "./store/reducers/sucursales";
import comprasReducer from "./store/reducers/compras";
import gastosReducer from "./store/reducers/gastos";
import authReducer from "./store/reducers/auth";
import snackbarReducer from "./store/reducers/snackbar";
import monedasReducer from "./store/reducers/monedas";
import multiempresaReducer from "./store/reducers/multiempresa";
import configuracionesReducer from "./store/reducers/configuraciones";
import ultimasActualizacionesReducer from "./store/reducers/ultimasActualizaciones";
import modalsReducer from "./store/reducers/modals";
import ejecucionPaginasReducer from "./store/reducers/ejecucionPaginas";
import clientesReducer from "./store/reducers/clientes";
import chequesReducer from "./store/reducers/cheques";
import proveedoresReducer from "./store/reducers/proveedores";
import procesosReducer from "./store/reducers/procesos";

const appReducer = combineReducers({
  auth: authReducer,
  snackbar: snackbarReducer,
  moneda: monedasReducer,
  configuraciones: configuracionesReducer,
  ultimasActualizaciones: ultimasActualizacionesReducer,
  modals: modalsReducer,
  ejecucionPaginas: ejecucionPaginasReducer,
  ventas: ventasReducer,
  compras: comprasReducer,
  multiempresa: multiempresaReducer,
  gastos: gastosReducer,
  sucursales: sucursalesReducer,
  filtros: filtrosReducer,
  clientes: clientesReducer,
  cheques: chequesReducer,
  proveedores: proveedoresReducer,
  procesos: procesosReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "AUTH_LOGOUT") {
    state = undefined;
  }

  return appReducer(state, action);
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

export default store;
