import * as actionTypes from "./actionTypes";

export const showGlobalModal = (
  show,
  title,
  body,
  footer,
  disableCloseButton,
  fullWidth,
  maxWidth,
  fullScreen = false
) => {
  return {
    type: actionTypes.SHOW_GLOBAL_MODAL,
    show,
    title,
    body,
    footer,
    disableCloseButton,
    fullWidth,
    fullScreen,
    maxWidth,
  };
};

export const changeGlobalModalBody = (newBody) => {
  return {
    type: actionTypes.CHANGE_GLOBAL_MODAL_BODY,
    newBody,
  };
};

export const showListFiltersModal = (show, title, filterTypes) => {
  return {
    type: actionTypes.SHOW_LIST_FILTERS_MODAL,
    show,
    title,
    filterTypes,
  };
};

export const hiddenGlobalModal = () => {
  const verifyNov = sessionStorage.getItem("vistoNovedades");
  !verifyNov && sessionStorage.setItem("vistoNovedades", true);
  return {
    type: actionTypes.HIDDEN_GLOBAL_MODAL,
  };
};

export const showSendChartModal = (show, chartRef, tituloChart) => {
  return {
    type: actionTypes.SHOW_SEND_CHART_MODAL,
    show,
    chartRef,
    tituloChart,
  };
};

export const showEstadoModal = (show, title, body) => {
  return {
    type: actionTypes.SHOW_ESTADO_MODAL,
    show,
    title,
    body,
  };
};

export const hiddenEstadoModal = () => {
  return {
    type: actionTypes.HIDDEN_ESTADO_MODAL,
  };
};

export const showBackButtonModal = (show) => {
  return {
    type: actionTypes.SHOW_BACK_BUTTON_MODAL,
    show,
  };
};

export const showErrorModal = (show, title, body) => {
  return {
    type: actionTypes.SHOW_ERROR_MODAL,
    show,
    title,
    body,
  };
};

export const hideErrorModal = () => {
  return {
    type: actionTypes.HIDE_ERROR_MODAL,
  };
};
