import * as actionTypes from "./actionTypes";
import axios from "../../axios-dashboard";
import moment from "moment";
//#region Máquinas
export const loadMaquinas = () => {
  return (dispatch) => {
    dispatch(loadMaquinasStart());
    const authStr = "Bearer ".concat(sessionStorage.getItem("token"));
    axios
      .get(`/maquina`, {
        headers: { Authorization: authStr },
      })
      .then((response) => {
        const data = [
          { id: "TD", nombre: "TODAS" },
          ...response.data.map((maquina) => ({
            id: maquina.idMaquina,
            nombre: maquina.nombre,
          })),
        ];
        dispatch(loadMaquinasSuccess(data));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.statusCode === 500) {
            dispatch(loadMaquinasFail(error));
          } else {
            dispatch(loadMaquinas());
          }
        } else {
          dispatch(loadMaquinasFail(error));
        }
      });
  };
};

const loadMaquinasStart = () => {
  return {
    type: actionTypes.LOAD_MAQUINAS_START,
  };
};

const loadMaquinasSuccess = (maquinas) => {
  return {
    type: actionTypes.LOAD_MAQUINAS_SUCCESS,
    maquinas,
  };
};

const loadMaquinasFail = (error) => {
  return {
    type: actionTypes.LOAD_MAQUINAS_FAIL,
    error,
  };
};
//#endregion

//#region Procesos en curso
export const loadProcesosEnCurso = (
  maquinaId,
  clienteId,
  materialId,
  fechaDesde,
  fechaHasta,
  ordenDeTrabajo,
  nroPrograma,
  espesor,
  terminados
) => {
  return (dispatch) => {
    dispatch(loadProcesosEnCursoStart());
    const authStr = "Bearer ".concat(sessionStorage.getItem("token"));
    axios
      .get(`trabajo`, {
        headers: {
          Authorization: authStr,
        },
      })
      .then((response) => {
        const data = response.data;
        dispatch(loadProcesosEnCursoSuccess(data));
      })
      .catch((error) => {
        loadProcesosEnCursoFail(false);
      });
  };
};

const loadProcesosEnCursoStart = () => {
  return {
    type: actionTypes.LOAD_PROCESOS_EN_CURSO_START,
  };
};

const loadProcesosEnCursoSuccess = (procesos) => {
  return {
    type: actionTypes.LOAD_PROCESOS_EN_CURSO_SUCCESS,
    procesos,
  };
};

const loadProcesosEnCursoFail = (error) => {
  return {
    type: actionTypes.LOAD_PROCESOS_EN_CURSO_FAIL,
    error,
  };
};
//#endregion

//#region Procesos terminados
export const loadProcesosTerminados = (
  maquinaId,
  clienteId,
  materialId,
  fechaDesde,
  fechaHasta,
  ordenDeTrabajo,
  nroPrograma,
  espesor,
  finalizado
) => {
  return (dispatch) => {
    dispatch(loadProcesosTerminadosStart());
    const authStr = "Bearer ".concat(sessionStorage.getItem("token"));
    axios
      .get(`trabajo?finalizado=${finalizado}`, {
        headers: {
          Authorization: authStr,
        },
      })
      .then((response) => {
        const data = response.data;
        dispatch(loadProcesosTerminadosSuccess(data));
      })
      .catch((error) => {
        loadProcesosTerminadosFail(false);
      });
  };
};

const loadProcesosTerminadosStart = () => {
  return {
    type: actionTypes.LOAD_PROCESOS_TERMINADOS_START,
  };
};

const loadProcesosTerminadosSuccess = (procesos) => {
  return {
    type: actionTypes.LOAD_PROCESOS_TERMINADOS_SUCCESS,
    procesos,
  };
};

const loadProcesosTerminadosFail = (error) => {
  return {
    type: actionTypes.LOAD_PROCESOS_TERMINADOS_FAIL,
    error,
  };
};
//#endregion

//#region Materiales
export const loadMateriales = () => {
  return (dispatch) => {
    dispatch(loadMaterialesStart());
    const data = [
      { id: "TD", nombre: "TODOS" },
      { id: "Propio", nombre: "Propio" },
      { id: "Cliente", nombre: "Cliente" },
    ];

    dispatch(loadMaterialesSuccess(data));
  };
};

const loadMaterialesStart = () => {
  return {
    type: actionTypes.LOAD_MATERIALES_START,
  };
};

const loadMaterialesSuccess = (materiales) => {
  return {
    type: actionTypes.LOAD_MATERIALES_SUCCESS,
    materiales,
  };
};

const loadMaterialesFail = (error) => {
  return {
    type: actionTypes.LOAD_MATERIALES_FAIL,
    error,
  };
};
//#endregion

//#region Detalle proceso
export const loadDetalleProceso = (
  procesoId,
  idSucursal,
  idSistema,
  idComproba,
  nroReferencia,
  cerrado,
  nroAlta
) => {
  return (dispatch) => {
    dispatch(loadDetalleProcesoStart());
    const authStr = "Bearer ".concat(sessionStorage.getItem("token"));
    axios
      .get(
        `detalleTrabajo?idTrabajo=${procesoId}&idSucursal=${idSucursal}&idSistema=${idSistema}&idComproba=${idComproba}&nroReferencia=${nroReferencia}&cerrado=${cerrado}&nroAlta=${nroAlta}`,
        {
          headers: {
            Authorization: authStr,
          },
        }
      )
      .then((response) => {
        const data = response.data;
        dispatch(loadDetalleProcesoSuccess(data));
      })
      .catch((error) => {
        loadDetalleProcesoFail(error);
      });
  };
};

const loadDetalleProcesoStart = () => {
  return {
    type: actionTypes.LOAD_DETALLE_PROCESO_START,
  };
};

const loadDetalleProcesoSuccess = (detalleProceso) => {
  return {
    type: actionTypes.LOAD_DETALLE_PROCESO_SUCCESS,
    detalleProceso,
  };
};

const loadDetalleProcesoFail = (error) => {
  return {
    type: actionTypes.LOAD_DETALLE_PROCESO_FAIL,
    error,
  };
};
//#endregion

//#region Cierre Tarea
export const loadCierreTarea = (chapasTarea) => {
  return (dispatch) => {
    if (
      chapasTarea.fechaFinalizacionTrabajoAnterior.isSame(
        moment("1899-12-30T00:00:00")
      ) ||
      moment(chapasTarea.fechaFinalizacion).isAfter(
        chapasTarea.fechaFinalizacionTrabajoAnterior
      )
    ) {
      dispatch(loadCierreTareaStart());
      const authStr = "Bearer ".concat(sessionStorage.getItem("token"));
      axios
        .post(`detalleTrabajo`, chapasTarea, {
          headers: {
            Authorization: authStr,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          dispatch(loadCierreTareaSuccess(response.data));
        })
        .catch((error) => {
          if (error.response && error.response.data)
            dispatch(loadCierreTareaFail(error.response.data));
          else {
            loadCierreTareaFail("");
          }
        });
    } else {
      dispatch(
        loadCierreTareaFail(
          "Error en Fecha de Cierre: Debe ser mayor a la fecha ingresada para el proceso precedente"
        )
      );
    }
  };
};

const loadCierreTareaStart = () => {
  return {
    type: actionTypes.LOAD_CIERRE_TAREA_START,
  };
};

const loadCierreTareaSuccess = (procesoEnCurso) => {
  return {
    type: actionTypes.LOAD_CIERRE_TAREA_SUCCESS,
    procesoEnCurso,
  };
};

const loadCierreTareaFail = (errorMessage) => {
  return {
    type: actionTypes.LOAD_CIERRE_TAREA_FAIL,
    error: errorMessage,
  };
};

export const clearCierreTarea = () => {
  return {
    type: actionTypes.CLEAR_CIERRE_TAREA,
  };
};

export const loadUpdateCierreTarea = (chapasTarea) => {
  return (dispatch) => {
    dispatch(loadUpdateCierreTareaStart());
    const authStr = "Bearer ".concat(sessionStorage.getItem("token"));
    axios
      .put(`detalleTrabajo`, chapasTarea, {
        headers: {
          Authorization: authStr,
          "Content-Type": "application/json",
        },
      })
      .then(() => {
        dispatch(loadUpdateCierreTareaSuccess());
      })
      .catch((error) => {
        if (error.response && error.response.data)
          dispatch(loadUpdateCierreTareaFail(error.response.data));
        else {
          dispatch(loadUpdateCierreTareaFail(""));
        }
      });
  };
};

const loadUpdateCierreTareaStart = () => {
  return {
    type: actionTypes.LOAD_UPDATE_CIERRE_TAREA_START,
  };
};

const loadUpdateCierreTareaSuccess = () => {
  return {
    type: actionTypes.LOAD_UPDATE_CIERRE_TAREA_SUCCESS,
  };
};

const loadUpdateCierreTareaFail = (errorMessage) => {
  return {
    type: actionTypes.LOAD_UPDATE_CIERRE_TAREA_FAIL,
    error: errorMessage,
  };
};

export const postChapasTarea = (chapasTarea) =>{
  return (dispatch) => {
    dispatch(postChapasTareaStart());
    const authStr = "Bearer ".concat(sessionStorage.getItem("token"));
    axios
      .post(`/DetalleTrabajo/ChapasTarea`, chapasTarea, {
        headers: { Authorization: authStr },
      })
      .then((response) => {
        dispatch(postChapasTareaSuccess(response.data));
      })
      .catch((error) => {
        if (error.response && error.response.data)
          dispatch(postChapasTareaFail(error.response.data));
        else {
          postChapasTareaFail("");
        }
      });;
  };
}

const postChapasTareaStart = () => {
  return {
    type: actionTypes.POST_CHAPAS_TAREA_START,
  };
};

const postChapasTareaSuccess = (procesoEnCurso) => {
  return {
    type: actionTypes.POST_CHAPAS_TAREA_SUCCESS,
    procesoEnCurso,
  };
};

const postChapasTareaFail = (errorMessage) => {
  return {
    type: actionTypes.POST_CHAPAS_TAREA_FAIL,
    error: errorMessage,
  };
};

export const clearPostChapasTarea = () => {
  return {
    type: actionTypes.CLEAR_POST_CHAPAS_TAREA,
  };
};
//#endregion

//#region Chapas
export const loadChapas = () => {
  return (dispatch) => {
    dispatch(loadChapasStart());
    const authStr = "Bearer ".concat(sessionStorage.getItem("token"));
    axios
      .get(`/chapas`, {
        headers: { Authorization: authStr },
      })
      .then((response) => {
        dispatch(loadChapasSuccess(response.data));
      });
  };
};

const loadChapasStart = () => {
  return {
    type: actionTypes.LOAD_CHAPAS_START,
  };
};

const loadChapasSuccess = (chapas) => {
  return {
    type: actionTypes.LOAD_CHAPAS_SUCCESS,
    chapas,
  };
};

const loadChapasFail = (error) => {
  return {
    type: actionTypes.LOAD_CHAPAS_FAIL,
    error,
  };
};

export const clearChapas = () => {
  return {
    type: actionTypes.CLEAR_CHAPAS,
  };
};
//#endregion

//#region Chapas tarea
export const loadChapasTarea = (
  idSucursal,
  idSistema,
  idComproba,
  nroReferencia,
  idArticulo,
  renglon,
  idTarea,
  ordenDeTrabajo
) => {
  return (dispatch) => {
    dispatch(loadChapasTareaStart());
    const authStr = "Bearer ".concat(sessionStorage.getItem("token"));
    axios
      .get(
        `/detalleTrabajo/chapasTarea?idSucursal=${idSucursal}&idSistema=${idSistema}&idComproba=${idComproba}&nroReferencia=${nroReferencia}&idArticulo=${idArticulo}&renglon=${renglon}&ordenDeTrabajo=${ordenDeTrabajo}&idTrabajo=${idTarea}`,
        {
          headers: { Authorization: authStr },
        }
      )
      .then((response) => {
        dispatch(loadChapasTareaSuccess(response.data));
      });
  };
};

const loadChapasTareaStart = () => {
  return {
    type: actionTypes.LOAD_CHAPAS_TAREA_START,
  };
};

const loadChapasTareaSuccess = (chapasTarea) => {
  return {
    type: actionTypes.LOAD_CHAPAS_TAREA_SUCCESS,
    chapasTarea,
  };
};

const loadChapasTareaFail = (error) => {
  return {
    type: actionTypes.LOAD_CHAPAS_TAREA_FAIL,
    error,
  };
};

export const clearChapasTarea = () => {
  return {
    type: actionTypes.CLEAR_CHAPAS_TAREA,
  };
};
//#endregion

//#region Archivos
export const descargarArchivos = (
  idSucursal,
  idSistema,
  idComproba,
  nroReferencia,
  idArticulo,
  renglon,
  tipoArchivo
) => {
  return (dispatch) => {
    dispatch(descargarArchivosStart());
    const authStr = "Bearer ".concat(sessionStorage.getItem("token"));
    axios
      .get(
        `/detalleTrabajo/Archivos?idSucursal=${idSucursal}&idSistema=${idSistema}&idComproba=${idComproba}&nroReferencia=${nroReferencia}&idArticulo=${idArticulo}&renglon=${renglon}&tipoArchivo=${tipoArchivo}`,
        {
          headers: { Authorization: authStr },
        }
      )
      .then((response) => response.data)
      .then((file) => {
        const binaryString = window.atob(file.fileContents);
        const bytes = new Uint8Array(binaryString.length);
        const arrayBuffer = bytes.map((byte, i) => binaryString.charCodeAt(i));

        // const blob = new Blob([arrayBuffer]);
        const blob = new Blob([arrayBuffer], { type: file.contentType });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = file.fileDownloadName;

        // link.setAttribute("download", file.fileDownloadName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        dispatch(descargarArchivosSuccess(response.data));
      })
      .catch((error) => {
        if (error.response && error.response.data)
          dispatch(descargarArchivosFail(error.response.data));
        else {
          dispatch(descargarArchivosFail(""));
        }
      });
  };
};

const descargarArchivosStart = () => {
  return {
    type: actionTypes.DESCARGAR_ARCHIVOS_START,
  };
};

const descargarArchivosSuccess = () => {
  return {
    type: actionTypes.DESCARGAR_ARCHIVOS_SUCCESS,
  };
};

const descargarArchivosFail = (error) => {
  return {
    type: actionTypes.DESCARGAR_ARCHIVOS_FAIL,
    error,
  };
};

//#endregion

//#region Procesos
export const loadProcesos = () => {
  return (dispatch) => {
    dispatch(loadProcesosStart());
    const authStr = "Bearer ".concat(sessionStorage.getItem("token"));
    axios
      .get(`/proceso`, {
        headers: { Authorization: authStr },
      })
      .then((response) => {
        const data = [
          { id: "TD", nombre: "TODOS" },
          ...response.data.map((proceso) => ({
            id: proceso.idProceso,
            nombre: proceso.nombre,
          })),
        ];
        dispatch(loadProcesosSuccess(data));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.statusCode === 500) {
            dispatch(loadProcesosFail(error));
          } else {
            dispatch(loadProcesos());
          }
        } else {
          dispatch(loadProcesosFail(error));
        }
      });
  };
}

const loadProcesosStart = () => {
    return {
      type: actionTypes.LOAD_PROCESOS_START,
    };
};

const loadProcesosSuccess = (procesos) => {
  return {
    type: actionTypes.LOAD_PROCESOS_SUCCESS,
    procesos,
  };
};

const loadProcesosFail = (error) => {
  return {
    type: actionTypes.LOAD_PROCESOS_FAIL,
    error,
  };
};
//#endregion