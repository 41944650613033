import { storageMonedaSigno } from "./sessionData";

export const formatNumber = (value) => {
  return Intl.NumberFormat("de-DE", {
    minimumFractionDigits: "2",
    maximumFractionDigits: "2",
  }).format(value);
};

export const formatTwoIntegerDigits = (value) => {
  return Intl.NumberFormat("de-DE", {
    minimumIntegerDigits: 2,
  }).format(value);
};

export const isEven = (value) => {
  return value % 2 === 0;
};

export const formatNumberWithMonedaSigno = (value, simbolo) => {
  var formatedNumber =
    simbolo !== undefined
      ? `${simbolo} ${formatNumber(value)}`
      : (formatedNumber = `${storageMonedaSigno()} ${formatNumber(value)}`);

  return formatedNumber;
};

export const range = (start, end) => {
  return Array(end - start + 1)
    .fill()
    .map((_, idx) => start + idx);
};
