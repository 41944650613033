import { Fragment, useRef } from "react";
import { isEven } from "../../../assets/shared/formatNumber";
import Table from "../../UI/Table/Table";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import { IconButton, Tooltip } from "@material-ui/core";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CierreTarea from "../CierreTarea/CierreTarea";
import { connect } from "react-redux";
import * as actions from "../../../store/actions/index";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
    height: "50%",
    backgroundColor: "white",
    paddingTop: "5px",
  },
  iconsHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    maxHeight: "30px",
  },
  table: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    height: "100%",
  },
}));

const TablaTareas = ({
  infoChapas,
  trabajos,
  espesor,
  materialId,
  postCierreTarea,
  onOpenCerrarTrabajoModal,
  onShowErrorModal,
  updateCierreTarea,
  postChapasTarea,
}) => {
  const tableRef = useRef();
  const classes = useStyles();

  const PuedeModificar = (orden) => {
    let espacios = -1;
    if (trabajos && trabajos.length !== 0) {
      for (var i = 0; i < trabajos.length; i++) {
        if (trabajos[i].orden === orden) espacios = 0;

        if (trabajos[i].actual) return espacios >= 0 && espacios <= 1;
        else espacios++;
      }
    }
    return espacios >= 0 && espacios <= 1;
  };

  const headerStyle = {
    fontSize: "14px",
    paddingTop: "0px",
    paddingBottom: "0px",
    backgroundColor: "#fafafa",
  };

  const cellStyles = {
    fontSize: "12px",
    paddingBottom: "5px",
    paddingTop: "5px",
    height: "49px",
  };

  const columns = [
    {
      title: ``,
      field: "",
      align: "left",
      sorting: false,
      cellStyle: (e, rowData) => {
        return {
          fontSize: "12px",
          paddingBottom: "5px",
          paddingTop: "5px",
          backgroundColor: referenceColor(
            rowData.fechaFinalizacion,
            rowData.fechaEgreso
          ),
          width: "1%",
        };
      },
      headerStyle: headerStyle,
      headerStyle: {
        fontSize: "14px",
        padding: "0px",
        backgroundColor: "#fafafa",
      },
    },
    {
      title: "Tarea",
      field: "nombreTarea",
      align: "left",
      cellStyle: cellStyles,
      headerStyle: headerStyle,
    },
    {
      title: `Fecha planificada de ingreso`,
      field: "fechaIngreso",
      align: "left",
      cellStyle: cellStyles,
      headerStyle: headerStyle,
      render: (rowData) =>
        `${
          rowData.fechaIngreso !== "1899-12-30T00:00:00"
            ? moment(rowData.fechaIngreso).format("DD/MM/YYYY")
            : ""
        }`,
    },
    {
      title: `Fecha planificada de egreso`,
      field: "fechaEgreso",
      align: "left",
      cellStyle: cellStyles,
      headerStyle: headerStyle,
      render: (rowData) =>
        `${
          rowData.fechaEgreso !== "1899-12-30T00:00:00"
            ? moment(rowData.fechaEgreso).format("DD/MM/YYYY")
            : ""
        }`,
    },
    {
      title: `Fecha finalización`,
      field: "fechaFinalizacion",
      align: "left",
      cellStyle: cellStyles,
      headerStyle: headerStyle,
      render: (rowData) =>
        `${
          rowData.fechaFinalizacion !== "1899-12-30T00:00:00"
            ? moment(rowData.fechaFinalizacion).format("DD/MM/YYYY")
            : ""
        }`,
    },
    {
      title: `Usuario finalización`,
      field: "usuarioFinalizacion",
      align: "left",
      cellStyle: cellStyles,
      headerStyle: headerStyle,
    },
    {
      title: `Fecha finaliza usuario`,
      field: "fechaFinalizacion",
      align: "left",
      cellStyle: cellStyles,
      headerStyle: headerStyle,
      render: (rowData) =>
        `${
          rowData.fechaFinalizacion !== "1899-12-30T00:00:00"
            ? moment(rowData.fechaFinalizacion).format("DD/MM/YYYY")
            : ""
        }`,
    },
    {
      title: `Hora finalización`,
      field: "horaFinalizacion",
      align: "left",
      cellStyle: cellStyles,
      headerStyle: headerStyle,
    },
    {
      title: `Cierre`,
      field: "fechaFinalizacion",
      align: "left",
      sorting: false,
      cellStyle: (cellData) => {
        return {
          fontSize: "12px",
          paddingBottom: "5px",
          paddingTop: "5px",
          color: cellData < 0 && "red",
          width: "1%",
        };
      },
      headerStyle: headerStyle,
      render: (rowData) => buildActionsButtons(rowData),
    },
  ];

  const data = () => {
    return (
      trabajos &&
      trabajos.map((trabajo) => {
        return {
          id: trabajo.idProceso,
          nombreTarea: trabajo.nombre,
          fechaIngreso: trabajo.fechaIngreso,
          fechaEgreso: trabajo.fechaEgreso,
          usuario: trabajo.usuario,
          fechaFinalizacion: trabajo.fechaFinalizacion,
          usuarioFinalizacion: trabajo.usuarioFinalizacion,
          horaFinalizacion: trabajo.horaFinalizacion,
          editaChapa: trabajo.editaChapa,
          orden: trabajo.orden,
          actual: trabajo.actual,
          modificable: trabajo.modificable,
        };
      })
    );
  };

  const referenceColor = (fechaFinalizacion, fechaEgreso) => {
    return (fechaFinalizacion !== "1899-12-30T00:00:00" &&
      moment(fechaFinalizacion).isAfter(moment(fechaEgreso), "day")) ||
      (fechaFinalizacion === "1899-12-30T00:00:00" &&
        moment().isAfter(moment(fechaEgreso), "day"))
      ? "#F24444"
      : fechaFinalizacion !== "1899-12-30T00:00:00"
      ? "#80C16E"
      : null;
  };

  const buildActionsButtons = (rowData) => {
    return (
      <div style={{ textAlign: "right" }}>
        <Tooltip title="Finalizar tarea">
          <IconButton
            size="small"
            onClick={() =>
              openCierreTrabajo(
                rowData.id,
                rowData.fechaFinalizacion,
                rowData.editaChapa,
                rowData.orden,
                rowData.modificable
              )
            }
          >
            {rowData.fechaFinalizacion !== "1899-12-30T00:00:00" ? (
              <VisibilityIcon fontSize="small" />
            ) : (
              <CheckCircleIcon fontSize="small" />
            )}
          </IconButton>
        </Tooltip>
      </div>
    );
  };

  const openCierreTrabajo = (
    id,
    fechaFinalizacion,
    editaChapa,
    orden,
    modificable
  ) => {
    const title = "Cerrar tarea";
    const puedeModificar = PuedeModificar(orden, modificable);
    let trabajosSorted = trabajos.sort((a, b) => a.orden - b.orden);
    const body = cierreDelTrabajo(
      id,
      fechaFinalizacion,
      editaChapa,
      puedeModificar,
      trabajosSorted,
      modificable
    );

    onOpenCerrarTrabajoModal(true, title, body);
  };

  const cierreDelTrabajo = (
    id,
    fechaFinalizacion,
    editaChapa,
    puedeModificar,
    trabajosSorted,
    puedeEscribir
  ) => {
    return (
      <CierreTarea
        infoChapas={infoChapas}
        idTarea={id}
        idMaterial={materialId}
        espesor={espesor}
        editaChapa={editaChapa}
        onClickGrabarHandler={postCierreTarea}
        onShowErrorModal={onShowErrorModal}
        consulta={fechaFinalizacion !== "1899-12-30T00:00:00"}
        onClickModificarHandler={updateCierreTarea}
        modificacion={puedeModificar}
        trabajosSorted={trabajosSorted}
        puedeEscribir={puedeEscribir}
        onClickPostChapasHandler={postChapasTarea}
      />
    );
  };

  return (
    <Fragment>
      <div className={classes.root}>
        <div className={classes.table}>
          <Table
            tableRef={tableRef}
            title=""
            style={{
              width: "100%",
              borderRadius: "0px",
              boxShadow: "none",
            }}
            loading={false}
            columns={columns}
            data={data()}
            noBoxShadow
            customOptions={{
              thirdSortClick: false,
              sorting: true,
              grouping: false,
              draggable: false,
              search: false,
              paging: false,
              toolbar: false,
              showTitle: false,
              maxBodyHeight: 325,
              rowStyle: (rowData, index) => ({
                backgroundColor: !isEven(index) && "#f2f2f2",
              }),
            }}
          />
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    onOpenCerrarTrabajoModal: (show, title, body) =>
      dispatch(actions.showGlobalModal(show, title, body)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(TablaTareas);
