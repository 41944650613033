import * as actionTypes from "./actionTypes";
import axiosAuth from "axios";
import { createBrowserHistory } from "history";
import { baseUrlDashboard, urlPortal } from "../../assets/shared/urlApps";
import { storageEmpresaId } from "../../assets/shared/sessionData";

export const login = (token) => {
  return (dispatch) => {
    dispatch(authStart());

    axiosAuth
      .get(`${baseUrlDashboard()}token/CheckToken?aplicacionId=8`, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "*/*",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.status !== 200) logout();

        const user = response.data;
        dispatch(setUserInfo(user));
        createBrowserHistory().push("/");
        fillSessionValues(user, token);
        dispatch(authSuccess(user.token));
        dispatch(checkAuthTimeout(24 * 60 * 60));
      })
      .catch((error) => {
        urlPortal();

        if (error.response) dispatch(authFail(error.response.data));
        else
          dispatch(authFail("Se produjo un error conectando con el servidor"));
      });
  };
};

export const authCheckState = () => {
  return (dispatch) => {
    dispatch(viewTokenIframe());

    const token = sessionStorage.getItem("token");
    if (!token) {
      dispatch(logout());
    } else {
      const expirationDate = new Date(sessionStorage.getItem("expirationDate"));

      if (expirationDate > new Date())
        dispatch(
          checkAuthTimeout(
            (expirationDate.getTime() - new Date().getTime()) / 1000
          )
        );
      else dispatch(logout());
    }
  };
};

const viewTokenIframe = () => {
  return (dispatch) => {
    window.onload = () => {
      // Defining helper frame's window object.
      let helperFrameWindow =
        document.getElementById("helperFrame").contentWindow;

      let getUserId = () => {
        // Sending post message to read data from helper frame
        helperFrameWindow.postMessage("getUserId", "*");
      };
      // Capture incoming messages from helper frame
      window.addEventListener("message", (event) => {
        if (typeof event.data === "string") {
          if (event.data === "sinToken") {
            dispatch(logout());
            urlPortal();
          }

          if (!sessionStorage.getItem("token")) {
            dispatch(checkToken(event.data, true, undefined, true));
          } else {
            const verifiDiferentToken =
              sessionStorage.getItem("token") !== event.data;

            dispatch(checkToken(event.data, verifiDiferentToken, undefined));
          }
        }
      });
      getUserId();
    };
  };
};

const checkToken = (
  token,
  diferentToken,
  idEmpresa = storageEmpresaId(),
  primeraCarga
) => {
  return (dispatch) => {
    dispatch(authStart());

    fillSessionValues(undefined, token);
    dispatch(authSuccess(token, false));

    // axiosAuth
    //   .get(
    //     `${baseUrlDashboard()}token/CheckToken?aplicacionId=8${
    //       idEmpresa ? `&empresaId=${idEmpresa}` : ""
    //     }`,
    //     {
    //       headers: { Authorization: `Bearer ${token}` },
    //     }
    //   )
    //   .then((response) => {
    //     if (response.status !== 200) logout();

    //     if (response) {
    //       const user = response.data;
    //       dispatch(setUserInfo(user));
    //       fillSessionValues(user, token);

    //       if (diferentToken) {
    //         sessionStorage.setItem("empresaId", user.empresaPorDefectoId);
    //         sessionStorage.setItem("empresaNombre", user.empresaPorDefectoNombre);
    //       }
    //       dispatch(authSuccess(user.token, user.ajustaInflacion));
    //       dispatch(checkAuthTimeout(24 * 60 * 60));
    //     }
    //   })
    //   .catch((error) => {
    //     if (error.response) dispatch(authFail(error.response.data));
    //     else
    //       dispatch(authFail("Se produjo un error conectando con el servidor"));
    //   });
  };
};

const setUserInfo = (user) => {
  return {
    type: actionTypes.SET_USER_INFO,
    user,
  };
};

const checkAuthTimeout = (expiresIn) => {
  return (dispatch) => {
    setTimeout(() => {
      dispatch(logout());
    }, expiresIn * 1000);
  };
};

const fillSessionValues = (user, token) => {
  sessionStorage.setItem("token", token);
  // sessionStorage.setItem("expirationDate", user.expiration);
  // sessionStorage.setItem("userEmail", user.email);
  // sessionStorage.setItem("clienteId", user.clienteId);
  // sessionStorage.setItem("isAdmin", user.isAdmin);
  // sessionStorage.setItem("tieneVendedor", user.tieneVendedor);
  // sessionStorage.setItem("fechaFija", user.fechaFija);
  // sessionStorage.setItem("multiempresa", user.tieneMultiempresa);
  // sessionStorage.setItem("empresaId", user.empresaPorDefectoId);
  sessionStorage.setItem("empresaNombre", "Dessol Industrial S.A.");
  // sessionStorage.setItem("monedaId", user.monedaId);
  // sessionStorage.setItem("monedaSigno", user.monedaSigno.trim());
  // sessionStorage.setItem("gauss", user.gauss);
  // sessionStorage.setItem("demo", user.demo);
};

export const logout = () => {
  sessionStorage.removeItem("token");
  sessionStorage.removeItem("expirationDate");
  sessionStorage.removeItem("monedaId");
  sessionStorage.removeItem("monedaSigno");
  sessionStorage.removeItem("multiempresa");
  sessionStorage.removeItem("empresaId");
  sessionStorage.removeItem("fechaFija");
  sessionStorage.removeItem("userEmail");
  sessionStorage.removeItem("demo");
  sessionStorage.clear();
  return { type: actionTypes.AUTH_LOGOUT };
};

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START,
  };
};

export const authSuccess = (token, ajustaInflacion) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    token,
    ajustaInflacion,
  };
};

const authFail = (error) => {
  return {
    type: actionTypes.AUTH_FAIL,
    error: error,
  };
};

export const setAuthRedirectPath = (path) => {
  return {
    type: actionTypes.AUTH_SET_REDIRECT_PATH,
    error: path,
  };
};
