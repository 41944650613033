import axios from "axios";
import { buildBearerToken } from "./assets/shared/sessionData";

const MAX_REQUESTS_COUNT = 1;
const INTERVAL_MS = 10;
let PENDING_REQUESTS = 0;

const configInstance = () => {
  let instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  instance.defaults.headers.common["Authorization"] = buildBearerToken();

  return instance;
};

const instance = configInstance();

instance.interceptors.request.use((config) => {
  config.headers.Authorization = buildBearerToken();

  return new Promise((resolve, reject) => {
    let interval = setInterval(() => {
      if (PENDING_REQUESTS < MAX_REQUESTS_COUNT) {
        PENDING_REQUESTS++;
        clearInterval(interval);
        resolve(config);
      }
    }, INTERVAL_MS);
  });
});

/**
 * Axios Response Interceptor
 */
instance.interceptors.response.use(
  (response) => {
    PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1);
    return Promise.resolve(response);
  },
  (error) => {
    PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1);
    return Promise.reject(error);
  }
);

export default instance;
