import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utilities";

const initialState = {
  clientesInfoMinima: {
    clientes: [],
    loading: false,
    error: null,
    estaCargado: false,
  },
  clienteById: {
    cliente: {},
    loading: false,
    error: null,
    clientSelected: false,
  },
  clienteCabeceraById: {
    cliente: null,
    loading: false,
    error: null,
  },
  ultimaCompra: {
    fecha: "",
    idComproba: "",
    nombre: "",
    referencia: 0,
    total: 0,
    loading: false,
    error: null,
  },
  cuentaCorriente: {
    comprobantes: [],
    loading: false,
    error: null,
    estaCargado: false,
  },
  evolucionGastosMensual: {
    ventas: [],
    ventasDataTable: [],
    total: 0,
    loading: false,
    estaCargado: false,
    error: null,
  },
  clientes: {
    clientes: [],
    loading: false,
    error: null,
    estaCargado: false,
  },
};

//#region loadClientesInfoMinima
const loadClientesInfoMinimaStart = (state) => {
  return updateObject(state, {
    clientesInfoMinima: updateObject(state.clientesInfoMinima, {
      loading: true,
    }),
  });
};

const loadClientesInfoMinimaSuccess = (state, clientes) => {
  return updateObject(state, {
    clientesInfoMinima: updateObject(state.clientesInfoMinima, {
      loading: false,
      clientes,
      estaCargado: true,
      error: null,
    }),
  });
};

const loadClientesInfoMinimaFail = (state, error) => {
  return updateObject(state, {
    clientesInfoMinima: updateObject(state.clientesInfoMinima, {
      loading: false,
      error,
    }),
  });
};

const clearClientesInfoMinima = (state) => {
  return updateObject(state, {
    clientesInfoMinima: updateObject(state.clientesInfoMinima, {
      clientes: [],
      loading: false,
      error: null,
      estaCargado: false,
    }),
  });
};
//#endregion

//#region loadClienteById
const loadClienteByIdStart = (state) => {
  return updateObject(state, {
    clienteById: updateObject(state.clienteById, {
      loading: true,
    }),
  });
};

const loadClienteByIdSuccess = (state, cliente) => {
  return updateObject(state, {
    clienteById: updateObject(state.clienteById, {
      loading: false,
      cliente,
      clientSelected: true,
      error: null,
    }),
  });
};

const loadClienteByIdFail = (state, error) => {
  return updateObject(state, {
    clienteById: updateObject(state.clienteById, {
      loading: false,
      error,
      clientSelected: false,
    }),
  });
};

const unmountClienteById = (state) => {
  return updateObject(state, {
    clienteById: updateObject(state.clienteById, {
      loading: false,
      cliente: {},
      error: null,
      clientSelected: false,
    }),
  });
};
//#endregion

//#region loadUltimaCompra
const loadUltimaCompraStart = (state) => {
  return updateObject(state, {
    ultimaCompra: updateObject(state.ultimaCompra, {
      loading: true,
    }),
  });
};

const loadUltimaCompraSuccess = (
  state,
  fecha,
  idComproba,
  nombre,
  referencia,
  total
) => {
  return updateObject(state, {
    ultimaCompra: updateObject(state.ultimaCompra, {
      fecha,
      idComproba,
      nombre,
      referencia,
      total,
      loading: false,
      error: null,
    }),
  });
};

const loadUltimaCompraFail = (state, error) => {
  return updateObject(state, {
    ultimaCompra: updateObject(state.ultimaCompra, {
      loading: false,
      fecha: "",
      idComproba: "",
      nombre: "",
      referencia: 0,
      total: 0,
      error,
    }),
  });
};

const clearUltimaCompra = (state) => {
  return updateObject(state, {
    ultimaCompra: updateObject(state.ultimaCompra, {
      fecha: "",
      idComproba: "",
      nombre: "",
      referencia: 0,
      total: 0,
      loading: false,
      error: null,
    }),
  });
};
//#endregion

//#region Cuenta Corriente
const loadCuentaCorrienteClienteStart = (state) => {
  return updateObject(state, {
    cuentaCorriente: updateObject(state.cuentaCorriente, {
      loading: true,
      estaCargado: false,
    }),
  });
};

const loadCuentaCorrienteClienteSuccess = (state, comprobantes) => {
  return updateObject(state, {
    cuentaCorriente: updateObject(state.cuentaCorriente, {
      loading: false,
      comprobantes,
      error: null,
      estaCargado: true,
    }),
  });
};

const loadCuentaCorrienteClienteFail = (state, error) => {
  return updateObject(state, {
    cuentaCorriente: updateObject(state.cuentaCorriente, {
      comprobantes: [],
      loading: false,
      error,
      estaCargado: true,
    }),
  });
};

const clearCuentaCorrienteCliente = (state) => {
  return updateObject(state, {
    cuentaCorriente: updateObject(state.cuentaCorriente, {
      comprobantes: [],
      loading: false,
      error: null,
      estaCargado: false,
    }),
  });
};
//#endregion

//#region load evolucion de ventas
const loadEvolucionDeGastosMensualStart = (state) => {
  return updateObject(state, {
    evolucionGastosMensual: updateObject(state.evolucionGastosMensual, {
      ventas: [],
      ventasDataTable: [],
      total: 0,
      loading: true,
      estaCargado: false,
      error: null,
    }),
  });
};

const loadEvolucionDeGastosMensualSuccess = (
  state,
  ventas,
  ventasDataTable,
  total
) => {
  return updateObject(state, {
    evolucionGastosMensual: updateObject(state.evolucionGastosMensual, {
      ventas,
      ventasDataTable,
      total,
      loading: false,
      estaCargado: true,
    }),
  });
};

const loadEvolucionDeGastosMensualFail = (state, error) => {
  return updateObject(state, {
    evolucionGastosMensual: updateObject(state.evolucionGastosMensual, {
      loading: false,
      error: error,
      estaCargado: true,
    }),
  });
};

const clearEvolucionDeGastosMensual = (state) => {
  return updateObject(state, {
    evolucionGastosMensual: updateObject(state.evolucionGastosMensual, {
      ventas: [],
      ventasDataTable: [],
      total: 0,
      loading: false,
      estaCargado: false,
      error: null,
    }),
  });
};
//#endregion

//#region Load Clientes
const loadClientesStart = (state) => {
  return updateObject(state, {
    clientes: updateObject(state.clientes, {
      loading: true,
      clientes: [],
      error: null,
      estaCargado: false,
    }),
  });
};
const loadClientesSuccess = (state, clientes) => {
  return updateObject(state, {
    clientes: updateObject(state.clientes, {
      loading: false,
      clientes: clientes,
      estaCargado: true,
    }),
  });
};
const loadClientesFail = (state, error) => {
  return updateObject(state, {
    clientes: updateObject(state.clientes, {
      loading: false,
      error,
      estaCargado: true,
    }),
  });
};
//#endregion
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_CLIENTE_BY_ID_START:
      return loadClienteByIdStart(state);
    case actionTypes.LOAD_CLIENTE_BY_ID_SUCCESS:
      return loadClienteByIdSuccess(state, action.cliente);
    case actionTypes.LOAD_CLIENTE_BY_ID_FAIL:
      return loadClienteByIdFail(state, action.error);
    case actionTypes.UNMOUNT_CLIENTE_BY_ID:
      return unmountClienteById(state);

    case actionTypes.LOAD_EVOLUCION_DE_GASTOS_START:
      return loadEvolucionDeGastosMensualStart(state);
    case actionTypes.LOAD_EVOLUCION_DE_GASTOS_SUCCESS:
      return loadEvolucionDeGastosMensualSuccess(
        state,
        action.ventas,
        action.ventasDataTable,
        action.totalVentas
      );
    case actionTypes.LOAD_EVOLUCION_DE_GASTOS_FAIL:
      return loadEvolucionDeGastosMensualFail(state, action.error);
    case actionTypes.CLEAR_EVOLUCION_DE_GASTOS:
      return clearEvolucionDeGastosMensual(state);

    case actionTypes.LOAD_ULTIMA_COMPRA_BY_CLIENTE_START:
      return loadUltimaCompraStart(state);
    case actionTypes.LOAD_ULTIMA_COMPRA_BY_CLIENTE_SUCCESS:
      return loadUltimaCompraSuccess(
        state,
        action.fecha,
        action.idComproba,
        action.nombre,
        action.referencia,
        action.total
      );
    case actionTypes.LOAD_ULTIMA_COMPRA_BY_CLIENTE_FAIL:
      return loadUltimaCompraFail(state, action.error);
    case actionTypes.CLEAR_ULTIMA_COMPRA_BY_CLIENTE:
      return clearUltimaCompra(state);

    case actionTypes.LOAD_CUENTA_CORRIENTE_CLIENTE_START:
      return loadCuentaCorrienteClienteStart(state);
    case actionTypes.LOAD_CUENTA_CORRIENTE_CLIENTE_SUCCESS:
      return loadCuentaCorrienteClienteSuccess(state, action.comprobantes);
    case actionTypes.LOAD_CUENTA_CORRIENTE_CLIENTE_FAIL:
      return loadCuentaCorrienteClienteFail(state, action.error);
    case actionTypes.CLEAR_CUENTA_CORRIENTE_CLIENTE:
      return clearCuentaCorrienteCliente(state);

    case actionTypes.LOAD_CLIENTES_START:
      return loadClientesStart(state);
    case actionTypes.LOAD_CLIENTES_SUCCESS:
      return loadClientesSuccess(state, action.clientes);
    case actionTypes.LOAD_CLIENTES_FAIL:
      return loadClientesFail(state, action.error);
    default:
      return state;
  }
};

export default reducer;
