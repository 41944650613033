// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DetalleTrabajo__DetalleTrabajo__3THsq{\r\n    display: flex;\r\n    flex-direction: row;\r\n    justify-content: space-between;\r\n    width: 100%;\r\n}\r\n\r\n.DetalleTrabajo__ClienteProductoMaquina__1a2Zz{\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: flex-start;\r\n    width: 100%;\r\n}\r\n.DetalleTrabajo__ClienteCotizacion__ZIy4g{\r\n    display: flex;\r\n    flex-direction: row;\r\n    width: 100%;\r\n    margin-bottom: 10px;\r\n}\r\n\r\n.DetalleTrabajo__Cliente__1Zvjw{\r\n    width: 30%; \r\n}\r\n.DetalleTrabajo__Cotizacion__qOZYN{\r\n    width: 25%;\r\n}\r\n.DetalleTrabajo__OrdenDeTrabajo__nolVt{\r\n    width: 25%; \r\n    font-size: 18px;\r\n\r\n}\r\n.DetalleTrabajo__Estado__29zDE{\r\n    width: 20%; \r\n    font-size: 18px;\r\n\r\n}\r\n.DetalleTrabajo__Producto__1w4F1{\r\n    display: flex;\r\n    flex-direction: column; \r\n    justify-content: space-evenly;\r\n}\r\n\r\n.DetalleTrabajo__Asignaciones__YXlEC{\r\n    display: flex;\r\n    flex-direction: row; \r\n    justify-content: space-around;\r\n}\r\n.DetalleTrabajo__CotizacionYPrograma__1u-sq{\r\n    display:flex; \r\n    flex-direction: column;\r\n    align-items: flex-end;\r\n    width: 25%;\r\n}\r\n\r\n\r\n.DetalleTrabajo__TypographyTitle__1wIOf{\r\n    font-size: 18px;\r\n    text-align: left;\r\n    \r\n}\r\n\r\n.DetalleTrabajo__TypographyBlock__31XuI{\r\n    width: 100%;\r\n    display: flex;\r\n    flex-direction: row;\r\n    justify-content: flex-start;\r\n}\r\n\r\n.DetalleTrabajo__BlockBorder__IGzIq{\r\n    width: 100%; \r\n    border-radius: 10px;\r\n    padding-top: 10px;\r\n    padding-left: 10px;\r\n    margin-bottom: 10px;\r\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"DetalleTrabajo": "DetalleTrabajo__DetalleTrabajo__3THsq",
	"ClienteProductoMaquina": "DetalleTrabajo__ClienteProductoMaquina__1a2Zz",
	"ClienteCotizacion": "DetalleTrabajo__ClienteCotizacion__ZIy4g",
	"Cliente": "DetalleTrabajo__Cliente__1Zvjw",
	"Cotizacion": "DetalleTrabajo__Cotizacion__qOZYN",
	"OrdenDeTrabajo": "DetalleTrabajo__OrdenDeTrabajo__nolVt",
	"Estado": "DetalleTrabajo__Estado__29zDE",
	"Producto": "DetalleTrabajo__Producto__1w4F1",
	"Asignaciones": "DetalleTrabajo__Asignaciones__YXlEC",
	"CotizacionYPrograma": "DetalleTrabajo__CotizacionYPrograma__1u-sq",
	"TypographyTitle": "DetalleTrabajo__TypographyTitle__1wIOf",
	"TypographyBlock": "DetalleTrabajo__TypographyBlock__31XuI",
	"BlockBorder": "DetalleTrabajo__BlockBorder__IGzIq"
};
export default ___CSS_LOADER_EXPORT___;
