import { Fragment } from "react";
import Button from "../../UI/Button/Button";
import classes from "./ErrorGrabacion.css";
import { connect } from "react-redux";
import * as actions from "../../../store/actions/index";

const ErrorGrabacion = ({ error, onHideModal, onClearError }) => {
  return (
    <Fragment>
      <p>{error}</p>
      <div className={classes.Cerrar}>
        <Button
          onClick={() => {
            onClearError();
            onHideModal(false, "");
          }}
        >
          Aceptar
        </Button>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {
    onHideModal: () => dispatch(actions.hideErrorModal()),
    onClearError: () => dispatch(actions.clearCierreTarea()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ErrorGrabacion);
