import * as actionTypes from "./actionTypes";
import { enqueueSnackbar } from "./snackbar";
import axiosConfig from "../../axios-config";
import axiosDashboard from "../../axios-dashboard";
import {
  storageEmpresaId,
  storageMonedaId,
  storageToken,
} from "../../assets/shared/sessionData";
import idIndicadores from "../../assets/shared/idIndicadores";
import { montoMesAnioToDataTablePorMesWithTotal } from "../../assets/constants/functions/formatMontoMesAnio";
import { fechaActualFunc, fechaFinPeriodoMesActualFunc } from "../../assets/shared/fechas";

//#region Configuraciones comprobantes
export const loadComprobantesPagos = (idEmpresa) => {
  return (dispatch) => {
    dispatch(loadComprobantesPagosStart());
    const authStr = "Bearer ".concat(storageToken());
    axiosConfig
      .get(
        `/compras/ComprobantesPagos?idEmpresa=${idEmpresa}&indicadorId=${idIndicadores.comprobantesPagos}`,
        {
          headers: { Authorization: authStr },
        }
      )
      .then((response) => {
        dispatch(loadComprobantesPagosSuccess(response.data));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.statusCode === 500) {
            dispatch(loadComprobantesPagosFail(error));
          } else {
            dispatch(loadComprobantesPagos(idEmpresa));
          }
        } else {
          dispatch(loadComprobantesPagosFail(error));
        }
      });
  };
};

const loadComprobantesPagosStart = () => {
  return {
    type: actionTypes.LOAD_COMPROBANTES_PAGOS_START,
  };
};

const loadComprobantesPagosSuccess = (comprobantesPagos) => {
  return {
    type: actionTypes.LOAD_COMPROBANTES_PAGOS_SUCCESS,
    comprobantesPagos,
  };
};

const loadComprobantesPagosFail = (error) => {
  return {
    type: actionTypes.LOAD_COMPROBANTES_PAGOS_FAIL,
    error,
  };
};

export const loadComprobantesCompras = (idEmpresa) => {
  return (dispatch) => {
    dispatch(loadComprobantesComprasStart());
    const authStr = "Bearer ".concat(storageToken());
    axiosConfig
      .get(
        `/compras/ComprobantesCompras?idEmpresa=${idEmpresa}&indicadorId=${idIndicadores.comprobantesCompras}`,
        {
          headers: { Authorization: authStr },
        }
      )
      .then((response) => {
        dispatch(loadComprobantesComprasSuccess(response.data));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.statusCode === 500) {
            dispatch(loadComprobantesComprasFail(error));
          } else {
            dispatch(loadComprobantesCompras(idEmpresa));
          }
        } else {
          dispatch(loadComprobantesComprasFail(error));
        }
      });
  };
};

const loadComprobantesComprasStart = () => {
  return {
    type: actionTypes.LOAD_COMPROBANTES_COMPRAS_START,
  };
};

const loadComprobantesComprasSuccess = (comprobantesCompras) => {
  return {
    type: actionTypes.LOAD_COMPROBANTES_COMPRAS_SUCCESS,
    comprobantesCompras,
  };
};

const loadComprobantesComprasFail = (error) => {
  return {
    type: actionTypes.LOAD_COMPROBANTES_COMPRAS_FAIL,
    error,
  };
};

export const postComprobantesPagos = (
  empresaId,
  comprobantes,
  success,
  fail
) => {
  return (dispatch) => {
    dispatch(executePostComprobantes(true));
    const authStr = "Bearer ".concat(storageToken());
    axiosConfig
      .post(`/compras/ComprobantesPagos?idEmpresa=${empresaId}`, comprobantes, {
        headers: { Authorization: authStr },
      })
      .then((response) => {
        dispatch(executePostComprobantes(false));
        window.location.reload();
      })
      .catch((error) => {
        dispatch(executePostComprobantes(false));
        dispatch(
          enqueueSnackbar({
            message: `Error al guardar los cambios, intente nuevamente`,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "warning",
            },
          })
        );
      });
  };
};

export const postComprobantesCompras = (empresaId, comprobantes) => {
  return (dispatch) => {
    dispatch(executePostComprobantes(true));
    const authStr = "Bearer ".concat(storageToken());
    axiosConfig
      .post(
        `/compras/ComprobantesCompras?idEmpresa=${empresaId}`,
        comprobantes,
        {
          headers: { Authorization: authStr },
        }
      )
      .then((response) => {
        dispatch(executePostComprobantes(false));
        window.location.reload();
      })
      .catch((error) => {
        dispatch(executePostComprobantes(false));

        dispatch(
          enqueueSnackbar({
            message: `Error al guardar los cambios, intente nuevamente`,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "warning",
            },
          })
        );
      });
  };
};

const executePostComprobantes = (loading) => {
  return {
    type: actionTypes.EXECUTE_POST_COMPROBANTES,
    loading,
  };
};

//#endregion

export const seleccionOrdenamientoDeudaDelMesProveedoresPor = (
  ordenamientoSelected
) => {
  return {
    type: actionTypes.SELECCION_DATA_DEUDA_DEL_MES_PROVEEDORES_POR,
    ordenamientoSelected,
  };
};

export const seleccionFechaDeudaDelMesProveedoresPor = (mes, anio) => {
  return {
    type: actionTypes.SELECCION_FECHA_DEUDAS_DEL_MES_ANIO_PROVEEDORES,
    mes,
    anio,
  };
};

export const loadDeudaPorMesProveedores = (idEmpresa = storageEmpresaId()) => {
  return (dispatch) => {
    dispatch(loadDeudaPorMesProveedoresStart());

    axiosDashboard
      .get(
        `/compras/DeudaPorMes?idMoneda=${storageMonedaId()}&idEmpresa=${idEmpresa}&indicadorId=${
          idIndicadores.evolucionComprasMensual
        }`
      )
      .then((response) => {
        const { formatedData: deudasDataTable, total: totalDeudas } =
          montoMesAnioToDataTablePorMesWithTotal(response.data);

        dispatch(
          loadDeudaPorMesProveedoresSuccess(
            response.data,
            deudasDataTable,
            totalDeudas
          )
        );
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.statusCode === 500) {
            dispatch(loadDeudaPorMesProveedoresFail(error));
          } else {
            dispatch(loadDeudaPorMesProveedores(idEmpresa));
          }
        } else {
          dispatch(loadDeudaPorMesProveedoresFail(error));
        }
      });
  };
};

export const clearDeudaPorMesProveedores = () => {
  return {
    type: actionTypes.CLEAR_DEUDA_POR_MES_PROVEEDORES,
  };
};

const loadDeudaPorMesProveedoresStart = () => {
  return {
    type: actionTypes.LOAD_DEUDA_POR_MES_PROVEEDORES_START,
  };
};

const loadDeudaPorMesProveedoresSuccess = (
  deudas,
  deudasDataTable,
  totalDeudas
) => {
  return {
    type: actionTypes.LOAD_DEUDA_POR_MES_PROVEEDORES_SUCCESS,
    deudas,
    deudasDataTable,
    totalDeudas,
  };
};

const loadDeudaPorMesProveedoresFail = (error) => {
  return {
    type: actionTypes.LOAD_DEUDA_POR_MES_PROVEEDORES_FAIL,
    error,
  };
};

export const loadDeudaDelMesPorOrdenamientoProveedores = (
  ordenamiento,
  mes = 0,
  anio = 0,
  idEmpresa = storageEmpresaId()
) => {
  return (dispatch) => {
    dispatch(loadDeudaDelMesPorOrdenamientoProveedoresStart(ordenamiento));
    axiosDashboard
      .get(
        `/compras/DeudaDelMes?idMoneda=${storageMonedaId()}&ordenamiento=${ordenamiento}&mes=${mes}&anio=${anio}&idEmpresa=${idEmpresa}&indicadorId=${
          idIndicadores.comprasPorCategoriaDeCliente
        }`
      )
      .then((response) => {
        const deudasNegativo = response.data.filter((rd) => rd.monto < 0);

        const formatDeudasDataTable = response.data || [];

        let totalDeudas = 0;

        response.data.map((venta) => (totalDeudas += venta.monto));
        let fecha = fechaActualFunc();

        fecha =
          response.data.length > 0
            ? new Date(response.data[0].anio, response.data[0].mes - 1)
            : new Date();

        dispatch(
          loadDeudaDelMesPorOrdenamientoProveedoresSuccess(
            response.data,
            deudasNegativo,
            formatDeudasDataTable,
            fecha,
            totalDeudas,
            ordenamiento
          )
        );
      })
      .catch((error) => {
        dispatch(
          loadDeudaDelMesPorOrdenamientoProveedoresFail(error, ordenamiento)
        );
      });
  };
};

export const clearDeudaDelMesPorOrdenamientoProveedores = (ordenamiento) => {
  return {
    type: actionTypes.CLEAR_DEUDA_DEL_MES_POR_ORDENAMIENTO_PROVEEDORES,
    ordenamiento,
  };
};

const loadDeudaDelMesPorOrdenamientoProveedoresStart = (ordenamiento) => {
  return {
    type: actionTypes.LOAD_DEUDA_DEL_MES_POR_ORDENAMIENTO_START_PROVEEDORES,
    ordenamiento,
  };
};

const loadDeudaDelMesPorOrdenamientoProveedoresSuccess = (
  deudas,
  deudasNegativo,
  deudasDataTable,
  fecha,
  totalDeudas,
  ordenamiento
) => {
  return {
    type: actionTypes.LOAD_DEUDA_DEL_MES_POR_ORDENAMIENTO_SUCCESS_PROVEEDORES,
    deudas,
    deudasNegativo,
    deudasDataTable,
    fecha,
    totalDeudas,
    ordenamiento,
  };
};

const loadDeudaDelMesPorOrdenamientoProveedoresFail = (error, ordenamiento) => {
  return {
    type: actionTypes.LOAD_DEUDA_DEL_MES_POR_ORDENAMIENTO_FAIL_PROVEEDORES,
    error,
    ordenamiento,
  };
};

export const loadAperturaDeDeudaProveedores = (
  { id, ordenamiento, mes, anio, idEmpresa = storageEmpresaId() },
  final
) => {
  return (dispatch) => {
    axiosDashboard
      .get(
        `/compras/AperturaDeDeuda?idMoneda=${storageMonedaId()}&id=${id}&ordenamiento=${ordenamiento}&mes=${mes}&anio=${anio}&idEmpresa=${idEmpresa}&indicadorId=${
          idIndicadores.comprasDelMesPorArticuloDelCliente
        }`
      )
      .then((response) => {
        return final({ success: true, data: response.data });
      })
      .catch((error) => {
        return final({ success: false, data: error });
      });
  };
};

//#region Compras Mes Comparativo

export const loadCompraDelMesComparativo = (idProveedor) => {
  return (dispatch) => {
    dispatch(loadCompraDelMesComparativoStart());

    axiosDashboard
      .get(
        `/compras/ComprasDelMes?idMoneda=${storageMonedaId()}&idProveedor=${idProveedor}&idEmpresa=${storageEmpresaId()}&indicadorId=${idIndicadores.comprasDelMes}`,
      )
      .then((response) => {
        const { montoMesActual, montoMesAnterior } = response.data;
        dispatch(loadCompraDelMesComparativoSuccess(montoMesActual, montoMesAnterior));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 500 || error.response.status === 404) {
            dispatch(loadCompraDelMesComparativoFail(error));
          } else {
            dispatch(loadCompraDelMesComparativo(idProveedor));
          }
        } else {
          dispatch(loadCompraDelMesComparativoFail(error));
        }
      });
  };
};

export const clearComprasMesComparativo = () => {
  return {
    type: actionTypes.CLEAR_COMPRAS_DEL_MES,
  };
};

const loadCompraDelMesComparativoStart = () => {
  return {
    type: actionTypes.LOAD_COMPRAS_DEL_MES_START,
  };
};

const loadCompraDelMesComparativoSuccess = (montoMesActual, montoMesAnterior) => {
  return {
    type: actionTypes.LOAD_COMPRAS_DEL_MES_SUCCESS,
    montoMesActual,
    montoMesAnterior,
  };
};

const loadCompraDelMesComparativoFail = (error) => {
  return {
    type: actionTypes.LOAD_COMPRAS_DEL_MES_FAIL,
    error,
  };
};

//#endregion

//#region Ordenes de Compras Pendientes

export const loadOrdenesDeComprasMesComparativo = (idProveedor, mes = 0, anio = 0) => {
  return (dispatch) => {
    dispatch(loadOrdenesDeComprasMesComparativoStart());

    axiosDashboard
      .get(
        `/compras/OrdenesDeCompraDelMes?idProveedor=${idProveedor}&mes=${mes}&anio=${anio}&idEmpresa=${
          storageEmpresaId()}&indicadorId=${idIndicadores.ordenesDeCompraDelMesComparativo}`,
      )
      .then((response) => {
        const { montoMesActual, montoMesAnterior, porcentajeDiferencia } = response.data;
        dispatch(
          loadOrdenesDeComprasMesComparativoSuccess(montoMesActual, montoMesAnterior, porcentajeDiferencia)
        );
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 500 || error.response.status === 404) {
            dispatch(loadOrdenesDeComprasMesComparativoFail(error));
          } else {
            dispatch(loadOrdenesDeComprasMesComparativo(idProveedor, mes, anio));
          }
        } else {
          dispatch(loadOrdenesDeComprasMesComparativoFail(error));
        }
      });
  };
};

export const clearOrdenesDeComprasMesComparativo = () => {
  return {
    type: actionTypes.CLEAR_ORDENES_DE_COMPRAS_DEL_MES,
  };
};

const loadOrdenesDeComprasMesComparativoStart = () => {
  return {
    type: actionTypes.LOAD_ORDENES_DE_COMPRAS_DEL_MES_START,
  };
};

const loadOrdenesDeComprasMesComparativoSuccess = (totalMesAct, totalMesAnt, porcTotal) => {
  return {
    type: actionTypes.LOAD_ORDENES_DE_COMPRAS_DEL_MES_SUCCESS,
    totalMesAnt,
    totalMesAct,
    porcTotal,
  };
};

const loadOrdenesDeComprasMesComparativoFail = (error) => {
  return {
    type: actionTypes.LOAD_ORDENES_DE_COMPRAS_DEL_MES_FAIL,
    error,
  };
};

export const loadCantidadOrdenesDeCompraDelMes = (idProveedor) => {
  return (dispatch) => {
    dispatch(loadCantidadOrdenesDeCompraDelMesStart());

    axiosDashboard
      .get(
        `/compras/CantidadOrdenesPendientes?idProveedor=${idProveedor}&idEmpresa=${
          storageEmpresaId()}&indicadorId=${idIndicadores.cantidadOrdenesDeCompraDelMes}`,
      )
      .then((response) => {
        const { cantidad, total, promedio30Dias } = response.data;
        dispatch(loadCantidadOrdenesDeCompraDelMesSuccess(cantidad, total, promedio30Dias));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 500 || error.response.status === 404) {
            dispatch(loadCantidadOrdenesDeCompraDelMesFail(error));
          } else {
            dispatch(loadCantidadOrdenesDeCompraDelMes(idProveedor));
          }
        } else {
          dispatch(loadCantidadOrdenesDeCompraDelMesFail(error));
        }
      });
  };
};

export const clearCantidadOrdenesDeCompraDelMes = () => {
  return {
    type: actionTypes.CLEAR_CANTIDAD_ORDENES_PENDIENTES,
  };
};

const loadCantidadOrdenesDeCompraDelMesStart = () => {
  return {
    type: actionTypes.LOAD_CANTIDAD_ORDENES_PENDIENTES_START,
  };
};

const loadCantidadOrdenesDeCompraDelMesSuccess = (cantidad, total, prom30Dias) => {
  return {
    type: actionTypes.LOAD_CANTIDAD_ORDENES_PENDIENTES_SUCCESS,
    cantidad,
    total,
    prom30Dias,
  };
};

const loadCantidadOrdenesDeCompraDelMesFail = (error) => {
  return {
    type: actionTypes.LOAD_CANTIDAD_ORDENES_PENDIENTES_FAIL,
    error,
  };
};

export const loadOrdenesDeCompraPendientes = (idProveedor) => {
  return (dispatch) => {
    dispatch(loadOrdenesDeCompraPendientesStart());
    let total = 0;

    axiosDashboard
      .get(
        `/compras/OrdenesDeCompraConDias?&idProveedor=${idProveedor}&idMoneda=${
          storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ordenesDeCompraPendientes}`,
      )
      .then((response) => {
        response.data.map((orden) => {
          return (total += orden.monto);
        });
        dispatch(loadOrdenesDeCompraPendientesSuccess(response.data, total));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 500 || error.response.status === 404) {
            dispatch(loadOrdenesDeCompraPendientesFail(error));
          } else {
            dispatch(loadOrdenesDeCompraPendientes(idProveedor));
          }
        } else {
          dispatch(loadOrdenesDeCompraPendientesFail(error));
        }
      });
  };
};

export const clearOrdenesDeCompraPendientes = () => {
  return {
    type: actionTypes.CLEAR_ORDENES_DE_COMPRA_PENDIENTES,
  };
};

const loadOrdenesDeCompraPendientesStart = () => {
  return {
    type: actionTypes.LOAD_ORDENES_DE_COMPRA_PENDIENTES_START,
  };
};

const loadOrdenesDeCompraPendientesSuccess = (ordenesDeCompraPendientes, total) => {
  return {
    type: actionTypes.LOAD_ORDENES_DE_COMPRA_PENDIENTES_SUCCESS,
    ordenesDeCompraPendientes,
    total,
  };
};

const loadOrdenesDeCompraPendientesFail = (error) => {
  return {
    type: actionTypes.LOAD_ORDENES_DE_COMPRA_PENDIENTES_FAIL,
    error,
  };
};

export const loadComprobantesOrdenesDeCompraPendientes = (idProveedor) => {
  return (dispatch) => {
    dispatch(loadComprobantesOrdenesDeCompraPendientesStart());

    axiosDashboard
      .get(
        `/compras/OrdenesDeCompraPendientes?idProveedor=${idProveedor}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.comprobantesOrdenesDeCompraPendientes}`,
      )
      .then((response) => {
        dispatch(loadComprobantesOrdenesDeCompraPendientesSuccess(response.data));          
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 500 || error.response.status === 404) {
            dispatch(loadComprobantesOrdenesDeCompraPendientesFail(error));              
          } else {
            dispatch(loadComprobantesOrdenesDeCompraPendientes(idProveedor));
          }
        } else {
          dispatch(loadComprobantesOrdenesDeCompraPendientesFail(error));
        }
      });
  };
};

export const clearComprobantesOrdenesDeCompraPendientes = () => {
  return {
    type: actionTypes.CLEAR_LOAD_ORDENES_COMPRA_PENDIENTES,
  };
};

const loadComprobantesOrdenesDeCompraPendientesStart = () => {
  return {
    type: actionTypes.LOAD_ORDENES_COMPRA_PENDIENTES_START,
  };
};

const loadComprobantesOrdenesDeCompraPendientesSuccess = (comprobantes) => {
  return {
    type: actionTypes.LOAD_ORDENES_COMPRA_PENDIENTES_SUCCESS,
    comprobantes,
  };
};

const loadComprobantesOrdenesDeCompraPendientesFail = (error) => {
  return {
    type: actionTypes.LOAD_ORDENES_COMPRA_PENDIENTES_FAIL,
    error,
  };
};

//#endregion

//#region Facturas Pendientes
export const loadFacturasDeCompraPendientes = (idProveedor) => {
  return (dispatch) => {
    dispatch(loadFacturasDeCompraPendientesStart());

    axiosDashboard
      .get(
        `/compras/DeudoresPorCompras?idMoneda=${storageMonedaId()}&idEmpresa=${
          storageEmpresaId()}&indicadorId=${idIndicadores.facturasPendientes}&idProveedor=${idProveedor}`
      )
      .then((response) => {
        const deudas = response.data;
        let totalDeuda = 0;
        response.data.map((deuda) => (totalDeuda += deuda.value));
        dispatch(loadFacturasDeCompraPendientesSuccess(deudas, totalDeuda));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 500 || error.response.status === 404) {
            dispatch(loadFacturasDeCompraPendientesFail(error));
          } else {
            dispatch(loadFacturasDeCompraPendientes(idProveedor));
          }
        } else {
          dispatch(loadFacturasDeCompraPendientesFail(error));
        }
      });
  };
};

export const clearFacturasDeCompraPendientes = () => {
  return {
    type: actionTypes.CLEAR_FACTURAS_COMPRA_PENDIENTES,
  };
};

const loadFacturasDeCompraPendientesStart = () => {
  return {
    type: actionTypes.LOAD_FACTURAS_COMPRA_PENDIENTES_START,
  };
};

const loadFacturasDeCompraPendientesSuccess = (facturas, totalFacturas) => {
  return {
    type: actionTypes.LOAD_FACTURAS_COMPRA_PENDIENTES_SUCCESS,
    facturas,
    totalFacturas,
  };
};

const loadFacturasDeCompraPendientesFail = (error) => {
  return {
    type: actionTypes.LOAD_FACTURAS_COMPRA_PENDIENTES_FAIL,
    error,
  };
};

export const loadComprobantesCompraFacturasPendientes = (idProveedor, fechaHasta = fechaFinPeriodoMesActualFunc()) => {
  return (dispatch) => {
    dispatch(loadComprobantesFacturasPendientesStart());

    axiosDashboard
      .get(
        `/compras/FacturasPendientes?idProveedor=${idProveedor}&idEmpresa=${storageEmpresaId()}&indicadorId=${idIndicadores.facturasPendientes}`
      )
      .then((response) => {
        dispatch(loadComprobantesFacturasPendientesSuccess(response.data));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 500 || error.response.status === 404) {
            dispatch(loadComprobantesCompraFacturasPendientesFail(error));
          } else {
            dispatch(loadComprobantesCompraFacturasPendientes(idProveedor, fechaHasta));
          }
        } else {
          dispatch(loadComprobantesCompraFacturasPendientesFail(error));
        }
      });
  };
};

export const clearComprobantesCompraFacturasPendientes = () => {
  return {
    type: actionTypes.CLEAR_COMPROBANTES_FACTURAS_COMPRA_PENDIENTES,
  };
};

const loadComprobantesFacturasPendientesStart = () => {
  return {
    type: actionTypes.LOAD_COMPROBANTES_FACTURAS_COMPRA_PENDIENTES_START,
  };
};

const loadComprobantesFacturasPendientesSuccess = (comprobantes) => {
  return {
    type: actionTypes.LOAD_COMPROBANTES_FACTURAS_COMPRA_PENDIENTES_SUCCESS,
    comprobantes,
  };
};

const loadComprobantesCompraFacturasPendientesFail = (error) => {
  return {
    type: actionTypes.LOAD_COMPROBANTES_FACTURAS_COMPRA_PENDIENTES_FAIL,
    error,
  };
};

//#endregion