import { useEffect, useState } from "react";
import { Grid, MenuItem, TextField, Typography } from "@material-ui/core";
import FiltroFechaSelector from "../UI/FiltroFechaSelector/FiltroFechaSelector";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import VirtualizedAutocomplete from "../UI/VirtualizedAutocomplete/VirtualizedAutocomplete";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import "moment/locale/es";

const useStyles = makeStyles((theme) => ({
  root: {},
  button: {
    margin: theme.spacing(1),
  },
  selector: {
    width: "100%",
    textAlign: "start",
    fontSize: "small",
    "& .MuiInputBase-input": {
      fontSize: "small",
    },
    "& .MuiInputLabel-root": {
      fontSize: "small",
    },
  },
  options: {
    fontSize: "small",
  },
  checkbox: {
    fontSize: "x-small",
    "& .MuiFormControlLabel-label": {
      fontSize: "x-small",
    },
  },
  referencias: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    fontSize: "small",
    backgroundColor: "#fff",
    width: "100%",
    borderRadius: "5px",
    padding: "0px 5px",
  },
  referenciaUrgencia: {
    backgroundColor: "#FEA163",
    padding: "2px 5px",
    borderRadius: "5px",
  },
  referenciaAtrasado: {
    backgroundColor: "#E50802",
    padding: "2px 5px",
    borderRadius: "5px",
  },
  referenciaParcial: {
    backgroundColor: "#6CBDFB",
    padding: "2px 5px",
    borderRadius: "5px",
  },
  referenciaPendiente: {
    backgroundColor: "#80C16E",
    padding: "2px 5px",
    borderRadius: "5px",
  },
}));

const TablaProcesosFiltros = ({
  maquinas,
  clientes,
  tipo,
  estados, 
  procesos, 
  handleOnChangeFilters,
  selectedOptions,
}) => {
  const classes = useStyles();
  const [desde, setDesde] = useState();
  const [hasta, setHasta] = useState();

  const onClick = () => {
    onChange(maquinaId);
    let aniosValues = anio;
    onAplicarFiltro(selectionId, desde, hasta, aniosValues);
    setCambiosAplicados(true);
  };

  return (
    <div>
      <Grid container spacing={1} style={{ marginTop: "5px" }}>
        
        <Grid item xs={4} md={2}>
          <VirtualizedAutocomplete
            id={"maquina"}
            data={maquinas}
            selectedOption={selectedOptions.maquina}
            handleOnChange={handleOnChangeFilters}
            nombre={"Máquina"}
          />
        </Grid>
        <Grid item xs={4} md={2}>
          <VirtualizedAutocomplete
            id={"cliente"}
            data={clientes}
            selectedOption={selectedOptions.cliente}
            handleOnChange={handleOnChangeFilters}
            nombre={"Cliente"}
          />
        </Grid>
        <Grid item xs={4} md={2}>
          <VirtualizedAutocomplete
            id={"tipo"}
            data={tipo}
            selectedOption={selectedOptions.tipo}
            handleOnChange={handleOnChangeFilters}
            nombre={"Material"}
          />
        </Grid>
        <Grid item xs={4} md={2}>
          <VirtualizedAutocomplete
            id={"proceso"}
            data={procesos}
            selectedOption={selectedOptions.proceso}
            handleOnChange={handleOnChangeFilters}
            nombre={"Proceso"}
          />
        </Grid>
        
        <Grid item xs={4} md={2}>
          <VirtualizedAutocomplete
            id={"estado"}
            data={estados}
            selectedOption={selectedOptions.estado}
            handleOnChange={handleOnChangeFilters}
            nombre={"Estado"}
          />
        </Grid>
      </Grid>

      <Grid
        container
        spacing={1}
        style={{ marginTop: "5px", marginBottom: "10px" }}
      >
        <Grid item xs={4} md={2}>
          <TextField
            id="ordenDeTrabajoSelected"
            label="Órden de trabajo"
            type="search"
            disabled={false}
            value={selectedOptions && selectedOptions.ordenDeTrabajo}
            defaultValue={""}
            onChange={(event) =>
              handleOnChangeFilters(
                1,
                "ordenDeTrabajo",
                event.target.value === "" ? undefined : event.target.value
              )
            }
            style={{ color: "black", width: "100%" }}
            className={classes.selector}
          />
        </Grid>
        <Grid item xs={4} md={2}>
          <TextField
            id="nroDeProgramaSelected"
            label="Número de programa"
            type="search"
            disabled={false}
            value={selectedOptions && selectedOptions.nroDePrograma}
            onChange={(event) =>
              handleOnChangeFilters(
                1,
                "nroDePrograma",
                event.target.value === ""
                  ? undefined
                  : Number(event.target.value)
              )
            }
            style={{ color: "black", width: "100%" }}
            className={classes.selector}
          />
        </Grid>
        <Grid item xs={4} md={2}>
          <TextField
            id="materialSelected"
            label="Calidad"
            type="search"
            disabled={false}
            value={selectedOptions && selectedOptions.material}
            defaultValue={""}
            onChange={(event) =>
              handleOnChangeFilters(
                1,
                "material",
                event.target.value === "" ? undefined : event.target.value
              )
            }
            style={{ color: "black", width: "100%" }}
            className={classes.selector}
          />
        </Grid>
        <Grid item xs={4} md={2}>
          <TextField
            id="espesorSelected"
            label="Espesor"
            type="number"
            inputProps={{
              maxLength: 5,
              step: "1",
            }}
            disabled={false}
            value={selectedOptions && selectedOptions.espesor}
            defaultValue={""}
            onChange={(event) =>
              handleOnChangeFilters(
                1,
                "espesor",
                event.target.value === ""
                  ? undefined
                  : Number(event.target.value)
              )
            }
            style={{ color: "black", width: "100%" }}
            className={classes.selector}
          />
        </Grid>
        
      </Grid>

      <Grid container spacing={1} style={{ marginTop: "5px", marginBottom: "10px" }}>
        <Grid container spacing={1} xs={8} md={8}>
          <Grid item xs={4} md={3}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy"
                margin="none"
                id="date-picker-inline"
                label="Desde"
                value={
                  selectedOptions && selectedOptions.fechaDesde !== undefined
                    ? selectedOptions.fechaDesde
                    : null
                }
                onChange={(value) =>
                  handleOnChangeFilters(6, "fechaDesde", value)
                }
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>

          <Grid item xs={4} md={3}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy"
                margin="none"
                id="date-picker-inline"
                label="Hasta"
                value={
                  selectedOptions && selectedOptions.fechaHasta !== undefined
                    ? selectedOptions.fechaHasta
                    : null
                }
                defaultValue={null}
                onChange={(value) =>
                  handleOnChangeFilters(6, "fechaHasta", value)
                }
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>

        </Grid>

          <Grid item xs={4} md={4} style={{ display: "flex" }}>
          <div className={classes.referencias}>
            <Typography variant="subtitle2" fontWeight>
              Referencias
            </Typography>
            <div className={classes.referenciaUrgencia}>
              <Typography variant="span">Urgencia</Typography>
            </div>
            <div className={classes.referenciaAtrasado}>
              <Typography variant="span">Atrasado</Typography>
            </div>
            <div className={classes.referenciaParcial}>
              <Typography variant="span">Parcial</Typography>
            </div>
            <div className={classes.referenciaPendiente}>
              <Typography variant="span">Pendiente</Typography>
            </div>
          </div>
          </Grid>
        </Grid>
    </div>
  );
};

export default TablaProcesosFiltros;
