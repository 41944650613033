import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

const VirtualizedAutocomplete = ({
  id,
  selectedOption,
  data,
  handleOnChange,
  nombre,
}) => {
  return (
    <Autocomplete
      id={id}
      options={data}
      getOptionLabel={(option) => option.nombre}
      renderInput={(params) => (
        <TextField {...params} variant="standard" label={nombre} />
      )}
      defaultValue={data && data[0]}
      onChange={(event, newValue) =>
        newValue !== undefined && newValue !== null
          ? handleOnChange(1, id, newValue)
          : null
      }
      value={selectedOption}
    />
  );
};

export default VirtualizedAutocomplete;
