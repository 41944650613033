import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utilities";

const initialState = {
  procesos: {
    loadingEnCurso: false,
    enCurso: [],
    terminados: [],
    errorEnCurso: null,
    estaCargadoEnCurso: false,
    loadingTerminados: false,
    terminados: [],
    errorTerminados: null,
    estaCargandoTerminados: null,
  },
  maquinas: {
    loading: false,
    maquinas: [],
    error: null,
    estaCargando: false,
  },
  materiales: {
    loading: false,
    materiales: [],
    error: null,
    estaCargando: false,
  },
  detalleProceso: {
    loading: false,
    detalle: {},
    error: null,
    estaCargando: false,
  },
  cierreTarea: {
    loading: false,
    error: null,
    estaCargando: false,
    success: false,
  },
  chapas: {
    loading: false,
    chapas: [],
    error: null,
    estaCargando: false,
  },
  chapasTarea: {
    loading: false,
    chapasTarea: {},
    error: null,
    estaCargando: false,
  },
  archivoDescargado: {
    loading: false,
    error: null,
    estaCargando: false,
  },
  postChapasTarea:{
    loading: false,
    error: null,
    estaCargando: false,
    success: false,
  },
  procesosDisponibles:{
    loading:false, 
    procesos:[], 
    error: null, 
    estaCargado: false,
  }
};

//#region Máquinas
const loadMaquinasStart = (state) => {
  return updateObject(state, {
    maquinas: updateObject(state.maquinas, {
      loading: true,
      maquinas: [],
      error: null,
      estaCargado: false,
    }),
  });
};

const loadMaquinasSuccess = (state, maquinas) => {
  return updateObject(state, {
    maquinas: updateObject(state.maquinas, {
      loading: false,
      maquinas: maquinas,
      estaCargado: true,
    }),
  });
};

const loadMaquinasFail = (state, error) => {
  return updateObject(state, {
    maquinas: updateObject(state.maquinas, {
      loading: false,
      error,
      estaCargado: true,
    }),
  });
};
//#endregion

//#region Procesos en curso
const loadProcesosEnCursoStart = (state) => {
  return updateObject(state, {
    procesos: updateObject(state.procesos, {
      loadingEnCurso: true,
      enCurso: [],
      errorEnCurso: null,
      estaCargadoEnCurso: false,
    }),
  });
};

const loadProcesosEnCursoSuccess = (state, procesos) => {
  return updateObject(state, {
    procesos: updateObject(state.procesos, {
      loadingEnCurso: false,
      enCurso: procesos,
      estaCargadoEnCurso: true,
    }),
  });
};

const loadProcesosEnCursoFail = (state, error) => {
  return updateObject(state, {
    procesos: updateObject(state.procesos, {
      loadingEnCurso: false,
      errorEnCurso,
      estaCargadoEnCurso: true,
    }),
  });
};
//#endregion

//#region Procesos terminados
const loadProcesosTerminadosStart = (state) => {
  return updateObject(state, {
    procesos: updateObject(state.procesos, {
      loadingTerminados: true,
      terminados: [],
      errorTerminados: null,
      estaCargadoTerminados: false,
    }),
  });
};

const loadProcesosTerminadosSuccess = (state, procesos) => {
  return updateObject(state, {
    procesos: updateObject(state.procesos, {
      loadingTerminados: false,
      terminados: procesos,
      estaCargadoTerminados: true,
    }),
  });
};

const loadProcesosTerminadosFail = (state, error) => {
  return updateObject(state, {
    procesos: updateObject(state.procesos, {
      loadingTerminados: false,
      errorTerminados,
      estaCargadoTerminados: true,
    }),
  });
};
//#endregion

//#region Materiales
const loadMaterialesStart = (state) => {
  return updateObject(state, {
    materiales: updateObject(state.materiales, {
      loading: true,
      materiales: [],
      error: null,
      estaCargado: false,
    }),
  });
};

const loadMaterialesSuccess = (state, materiales) => {
  return updateObject(state, {
    materiales: updateObject(state.materiales, {
      loading: false,
      materiales: materiales,
      estaCargado: true,
    }),
  });
};

const loadMaterialesFail = (state, error) => {
  return updateObject(state, {
    materiales: updateObject(state.materiales, {
      loading: false,
      error,
      estaCargado: true,
    }),
  });
};
//#endregion

//#region Detalle proceso
const loadDetalleProcesoStart = (state) => {
  return updateObject(state, {
    detalleProceso: updateObject(state.detalleProceso, {
      loading: true,
      detalle: {},
      error: null,
      estaCargado: false,
    }),
  });
};

const loadDetalleProcesoSuccess = (state, detalleProceso) => {
  return updateObject(state, {
    detalleProceso: updateObject(state.detalleProceso, {
      loading: false,
      detalle: detalleProceso,
      estaCargado: true,
    }),
  });
};

const loadDetalleProcesoFail = (state, error) => {
  return updateObject(state, {
    detalleProceso: updateObject(state.detalleProceso, {
      loading: false,
      error,
      estaCargado: true,
    }),
  });
};
//#endregion

//#region Cierre tarea
const loadCierreTareaStart = (state) => {
  return updateObject(state, {
    cierreTarea: updateObject(state.cierreTarea, {
      loading: true,
      error: null,
      estaCargado: false,
    }),
  });
};

const loadCierreTareaSuccess = (state, procesoEnCurso) => {
  const procesosEnCurso = [...state.procesos.enCurso];
  procesosEnCurso.forEach((proceso) => {
    if (
      proceso.idSucursal === procesoEnCurso.idSucursal &&
      proceso.idSistema === procesoEnCurso.idSistema &&
      proceso.idComproba === procesoEnCurso.idComproba &&
      proceso.nroReferencia === procesoEnCurso.nroReferencia &&
      proceso.ordenDeTrabajo === procesoEnCurso.id_sucursal
    )
      proceso.proceso = procesoEnCurso.nombre;
  });

  return updateObject(state, {
    cierreTarea: updateObject(state.cierreTarea, {
      loading: false,
      error: null,
      estaCargado: false,
      success: true,
    }),
    procesos: updateObject(state.procesos, {
      enCurso: procesosEnCurso,
    }),
  });
};

const loadCierreTareaFail = (state, error) => {
  return updateObject(state, {
    cierreTarea: updateObject(state.cierreTarea, {
      loading: false,
      error: error,
      estaCargado: false,
    }),
  });
};

const clearCierreTarea = (state) => {
  return updateObject(state, {
    cierreTarea: updateObject(state.cierreTarea, {
      loading: false,
      error: null,
      estaCargando: false,
      success: false,
    }),
  });
};

const loadUpdateCierreTareaStart = (state) => {
  return updateObject(state, {
    cierreTarea: updateObject(state.cierreTarea, {
      loading: true,
      error: null,
      estaCargado: false,
    }),
  });
};

const loadUpdateCierreTareaSuccess = (state) => {
  return updateObject(state, {
    cierreTarea: updateObject(state.cierreTarea, {
      loading: false,
      error: null,
      estaCargado: false,
      success: true,
    }),
  });
};

const loadUpdateCierreTareaFail = (state, error) => {
  return updateObject(state, {
    cierreTarea: updateObject(state.cierreTarea, {
      loading: false,
      error: error,
      estaCargado: false,
    }),
  });
};

const postChapasTareaStart = (state) => {
  return updateObject(state, {
    postChapasTarea: updateObject(state.postChapasTarea, {
      loading: true,
      error: null,
      estaCargado: false,
    }),
  });
}

const postChapasTareaSuccess = (state) => {
  return updateObject(state, {
    postChapasTarea: updateObject(state.postChapasTarea, {
      loading: false,
      error: null,
      estaCargado: false,
      success: true,
    }),
  });
}

const postChapasTareaFail = (state, error) => {
  return updateObject(state, {
    postChapasTarea: updateObject(state.postChapasTarea, {
      loading: false,
      error: error,
      estaCargado: false,
    }),
  });
}

const clearPostChapasTarea = (state) => {
  return updateObject(state, {
    postChapasTarea: updateObject(state.postChapasTarea, {
      loading: false,
      error: null,
      estaCargando: false,
      success: false,
    }),
  });
}
//#endregion

//#region Chapas
const loadChapasStart = (state) => {
  return updateObject(state, {
    chapas: updateObject(state.chapas, {
      loading: true,
      chapas: [],
      error: null,
      estaCargado: false,
    }),
  });
};

const loadChapasSuccess = (state, chapas) => {
  return updateObject(state, {
    chapas: updateObject(state.chapas, {
      loading: false,
      chapas: chapas,
      estaCargado: true,
    }),
  });
};

const loadChapasFail = (state, error) => {
  return updateObject(state, {
    chapas: updateObject(state.chapas, {
      loading: false,
      error,
      estaCargado: true,
    }),
  });
};

const clearChapas = (state) => {
  return updateObject(state, {
    chapas: updateObject(state.chapas, {
      loading: false,
      error: null,
      estaCargando: false,
      chapas: [],
    }),
  });
};
//#endregion

//#region Chapas tarea
const loadChapasTareaStart = (state) => {
  return updateObject(state, {
    chapasTarea: updateObject(state.chapasTarea, {
      loading: true,
      chapasTarea: {},
      error: null,
      estaCargado: false,
    }),
  });
};

const loadChapasTareaSuccess = (state, chapasTarea) => {
  return updateObject(state, {
    chapasTarea: updateObject(state.chapasTarea, {
      loading: false,
      chapasTarea: chapasTarea,
      estaCargado: true,
    }),
  });
};

const loadChapasTareaFail = (state, error) => {
  return updateObject(state, {
    chapasTarea: updateObject(state.chapasTarea, {
      loading: false,
      error,
      estaCargado: true,
    }),
  });
};

const clearChapasTarea = (state) => {
  return updateObject(state, {
    chapasTarea: updateObject(state.chapasTarea, {
      loading: false,
      error: null,
      estaCargando: false,
      chapasTarea: {},
    }),
  });
};
//#endregion

//#region Descargar Archivos
const loadDescargarArchivoStart = (state) => {
  return updateObject(state, {
    archivoDescargado: updateObject(state.archivoDescargado, {
      loading: true,
      error: null,
      estaCargado: false,
    }),
  });
};

const loadDescargarArchivoSuccess = (state) => {
  return updateObject(state, {
    archivoDescargado: updateObject(state.archivoDescargado, {
      loading: false,
      estaCargado: true,
    }),
  });
};

const loadDescargarArchivoFail = (state, error) => {
  return updateObject(state, {
    archivoDescargado: updateObject(state.archivoDescargado, {
      loading: false,
      error,
      estaCargado: true,
    }),
  });
};

const clearDescargarArchivo = (state) => {
  return updateObject(state, {
    archivoDescargado: updateObject(state.archivoDescargado, {
      loading: false,
      error: null,
      estaCargando: false,
      archivo: {},
    }),
  });
};
//#endregion

//#region Procesos
const loadProcesosStart = (state) => {
  return updateObject(state, {
    procesosDisponibles: updateObject(state.procesosDisponibles, {
      loading: true,
      procesos: null,
      estaCargado: false,
    }),
  });
};

const loadProcesosSuccess = (state, procesos) => {
  return updateObject(state, {
    procesosDisponibles: updateObject(state.procesosDisponibles, {
      loading: false,
      procesos: procesos,
      estaCargado: true,
    }),
  });
};

const loadProcesosFail = (state, error) => {
  return updateObject(state, {
    procesosDisponibles: updateObject(state.procesosDisponibles, {
      loading: false,
      error,
      estaCargado: true,
    }),
  });
};

const clearProcesos = (state) => {
  return updateObject(state, {
    procesosDisponibles: updateObject(state.procesosDisponibles, {
      loading: false,
      error: null,
      estaCargando: false,
      procesos: [],
    }),
  });
};
//#endregion

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_MAQUINAS_START:
      return loadMaquinasStart(state);
    case actionTypes.LOAD_MAQUINAS_SUCCESS:
      return loadMaquinasSuccess(state, action.maquinas);
    case actionTypes.LOAD_MAQUINAS_FAIL:
      return loadMaquinasFail(state, action.error);
    case actionTypes.LOAD_PROCESOS_EN_CURSO_START:
      return loadProcesosEnCursoStart(state);
    case actionTypes.LOAD_PROCESOS_EN_CURSO_SUCCESS:
      return loadProcesosEnCursoSuccess(state, action.procesos);
    case actionTypes.LOAD_PROCESOS_EN_CURSO_FAIL:
      return loadProcesosEnCursoFail(state, action.error);
    case actionTypes.LOAD_PROCESOS_TERMINADOS_START:
      return loadProcesosTerminadosStart(state);
    case actionTypes.LOAD_PROCESOS_TERMINADOS_SUCCESS:
      return loadProcesosTerminadosSuccess(state, action.procesos);
    case actionTypes.LOAD_PROCESOS_TERMINADOS_FAIL:
      return loadProcesosTerminadosFail(state, action.error);
    case actionTypes.LOAD_MATERIALES_START:
      return loadMaterialesStart(state);
    case actionTypes.LOAD_MATERIALES_SUCCESS:
      return loadMaterialesSuccess(state, action.materiales);
    case actionTypes.LOAD_MATERIALES_FAIL:
      return loadMaterialesFail(state, action.error);
    case actionTypes.LOAD_DETALLE_PROCESO_START:
      return loadDetalleProcesoStart(state);
    case actionTypes.LOAD_DETALLE_PROCESO_SUCCESS:
      return loadDetalleProcesoSuccess(state, action.detalleProceso);
    case actionTypes.LOAD_DETALLE_PROCESO_FAIL:
      return loadDetalleProcesoFail(state, action.error);
    case actionTypes.LOAD_CIERRE_TAREA_START:
      return loadCierreTareaStart(state);
    case actionTypes.LOAD_CIERRE_TAREA_SUCCESS:
      return loadCierreTareaSuccess(state, action.procesoEnCurso);
    case actionTypes.LOAD_CIERRE_TAREA_FAIL:
      return loadCierreTareaFail(state, action.error);
    case actionTypes.CLEAR_CIERRE_TAREA:
      return clearCierreTarea(state);
    case actionTypes.LOAD_CHAPAS_START:
      return loadChapasStart(state);
    case actionTypes.LOAD_CHAPAS_SUCCESS:
      return loadChapasSuccess(state, action.chapas);
    case actionTypes.LOAD_CHAPAS_FAIL:
      return loadChapasFail(state);
    case actionTypes.CLEAR_CHAPAS:
      return clearChapas(state);
    case actionTypes.LOAD_CHAPAS_TAREA_START:
      return loadChapasTareaStart(state);
    case actionTypes.LOAD_CHAPAS_TAREA_SUCCESS:
      return loadChapasTareaSuccess(state, action.chapasTarea);
    case actionTypes.LOAD_CHAPAS_TAREA_FAIL:
      return loadChapasTareaFail(state);
    case actionTypes.CLEAR_CHAPAS_TAREA:
      return clearChapasTarea(state);
    case actionTypes.LOAD_UPDATE_CIERRE_TAREA_START:
      return loadUpdateCierreTareaStart(state);
    case actionTypes.LOAD_UPDATE_CIERRE_TAREA_SUCCESS:
      return loadUpdateCierreTareaSuccess(state);
    case actionTypes.LOAD_UPDATE_CIERRE_TAREA_FAIL:
      return loadUpdateCierreTareaFail(state, action.error);
    case actionTypes.DESCARGAR_ARCHIVOS_START:
      return loadDescargarArchivoStart(state);
    case actionTypes.DESCARGAR_ARCHIVOS_SUCCESS:
      return loadDescargarArchivoSuccess(state, action.archivo);
    case actionTypes.DESCARGAR_ARCHIVOS_FAIL:
      return loadDescargarArchivoFail(state, action.error);
    case actionTypes.POST_CHAPAS_TAREA_START: 
      return postChapasTareaStart(state); 
    case actionTypes.POST_CHAPAS_TAREA_SUCCESS: 
      return postChapasTareaSuccess(state);
    case actionTypes.POST_CHAPAS_TAREA_FAIL:
      return postChapasTareaFail(state, action.error); 
    case actionTypes.CLEAR_POST_CHAPAS_TAREA:
      return clearPostChapasTarea(state);
    case actionTypes.LOAD_PROCESOS_START:
      return loadProcesosStart(state);
    case actionTypes.LOAD_PROCESOS_SUCCESS:
      return loadProcesosSuccess(state, action.procesos);
    case actionTypes.LOAD_PROCESOS_FAIL:
      return loadProcesosFail(state);
    case actionTypes.CLEAR_PROCESOS:
      return clearProcesos(state);
  default:
      return state;
  }
};

export default reducer;
