import { dataOptions } from "../../assets/constants/dataOptions/OrdenamientoDeudasCliente";
import { fechaActualFunc } from "../../assets/shared/fechas";
import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utilities";

const initialState = {
  fechaDeudasDelMesAnio: {
    mes: fechaActualFunc().getMonth()+1,
    anio: fechaActualFunc().getFullYear(),
  },
  ordenamientoDeudaDelMesPorSelected: 0,
  comprobantesVentas: {
    loading: false,
    comprobantes: [],
    error: null,
    estaCargado: false,
  },
  comprobantesCobranzas: {
    loading: false,
    comprobantes: [],
    error: null,
    estaCargado: false,
  },
  categoriasDelCliente: {
    categorias: [],
    loading: false,
    error: null,
    estaCargado: false,
  },
  deudasPorMes: {
    deudas: [],
    deudasDataTable: [],
    totalDeudas: 0,
    loading: false,
    estaCargado: false,
    error: null,
  },
  deudaDelMesPorCategoriaDeCliente: {
    deudas: [],
    deudasNegativo: [],
    deudasDataTable: [],
    total: 0,
    loading: false,
    estaCargado: false,
    error: null,
    fecha: "",
  },
  deudaDelMesPorVendedor: {
    deudas: [],
    deudasNegativo: [],
    deudasDataTable: [],
    total: 0,
    loading: false,
    estaCargado: false,
    error: null,
    fecha: "",
  },
  deudaDelMesPorCliente: {
    deudas: [],
    deudasNegativo: [],
    deudasDataTable: [],
    total: 0,
    loading: false,
    estaCargado: false,
    error: null,
    fecha: "",
  },  
  comprobantesFacturasPendientes: {
    comprobantes: [],
    loading: false,
    estaCargado: false,
    error: null,
  },
  comprobantesPedidosPendientes: {
    comprobantes: [],
    loading: false,
    estaCargado: false,
    error: null,
  },
  ventasMesComparativo: {
    montoMesActual: 0,
    montoMesAnterior: 0,
    loading: false,
    error: null,
    estaCargado: false,
  }, 
  pedidosMesComparativo: {
    transMesAnt: 0,
    totalMesAnt: 0,
    transMesAct: 0,
    totalMesAct: 0,
    porcTrans: 0,
    porcTotal: 0,
    loading: false,
    loadingPorCliente: false,
    error: null,
    estaCargado: false,
    totalMesActualPorCliente: 0,
    totalMesAnteriorPorCliente: 0,
  },  
  ventasPorVendedor: {
    error: null,
    estaCargado: false,
    loading: false,
    unidadesVendidas: 0,
    ventas: 0,
    cantidad: 0,
    loadingPorCliente: false,
    unidadesVendidasPorCliente: 0,
    ventasPorCliente: 0,
    cantidadPorCliente: 0,
  },
  estadosPedidosPendientes: {
    pedidos: [],
    loading: false,
    error: null,
    estaCargado: false,
    total: 0,
  },
  facturasPendientes: {
    facturas: [],
    facturasPorCliente: [],
    totalFacturas: 0,
    totalFacturasPorCliente: 0,
    loading: false,
    loadingPorCliente: false,
    estaCargado: false,
    estaCargadoPorCliente: false,
    error: null,
    errorPorCliente: null,
  },  
  pedidosPendientes: {
    cantidad: 0,
    total: 0,
    prom30Dias: 0,
    cantidadPorCliente: 0,
    totalPorCliente: 0,
    prom30DiasPorCliente: 0,
    loading: false,
    loadingPorCliente: false,
    error: null,
    estaCargado: false,
  },
  aperturaDeudaPorCategoriaDeCliente: {
    items: [],
  },
  aperturaDeudaPorVendedor: {
    items: [],
  },
};

const keyOrdenamiento = (ordenamiento) => {
  return dataOptions[ordenamiento].storeStateKey;
};

//#region seleccion fechaDeudasDelMesAnio
const seleccionFechaDeudasDelMesAnio = (state, mes, anio) => {
  return updateObject(state, {
    fechaDeudasDelMesAnio: updateObject(state.fechaDeudasDelMesAnio, {
      mes,
      anio,
    }),
  });
};

//#endregion

//#region load comprobantes de Ventas
const loadComprobantesVentasStart = (state) => {
  return updateObject(state, {
    comprobantesVentas: updateObject(state.comprobantesVentas, {
      loading: true,
      comprobantes: [],
      error: null,
    }),
  });
};

const loadComprobantesVentasSuccess = (state, comprobantes) => {
  return updateObject(state, {
    comprobantesVentas: updateObject(state.comprobantesVentas, {
      error: null,
      loading: false,
      comprobantes,
      estaCargado: true,
    }),
  });
};

const loadComprobantesVentasFail = (state, error) => {
  return updateObject(state, {
    comprobantesVentas: updateObject(state.comprobantesVentas, {
      loading: false,
      error: error,
      estaCargado: true,
    }),
  });
};
//#endregion

//#region load comprobantes de Cobranzas
const loadComprobantesCobranzasStart = (state) => {
  return updateObject(state, {
    comprobantesCobranzas: updateObject(state.comprobantesCobranzas, {
      loading: true,
      comprobantes: [],
      error: null,
    }),
  });
};

const loadComprobantesCobranzasSuccess = (state, comprobantes) => {
  return updateObject(state, {
    comprobantesCobranzas: updateObject(state.comprobantesCobranzas, {
      error: null,
      loading: false,
      comprobantes,
      estaCargado: true,
    }),
  });
};

const loadComprobantesCobranzasFail = (state, error) => {
  return updateObject(state, {
    comprobantesCobranzas: updateObject(state.comprobantesCobranzas, {
      loading: false,
      error: error,
      estaCargado: true,
    }),
  });
};
//#endregion

//#region load Categorias del cliente
const loadCategoriasDelClienteStart = (state) => {
  return updateObject(state, {
    categoriasDelCliente: updateObject(state.categoriasDelCliente, {
      loading: true,
    }),
  });
};

const loadCategoriasDelClienteSuccess = (state, categorias) => {
  return updateObject(state, {
    categoriasDelCliente: updateObject(state.categoriasDelCliente, {
      categorias,
      error: null,
      loading: false,
      estaCargado: true,
    }),
  });
};

const loadCategoriasDelClienteFail = (state, error) => {
  return updateObject(state, {
    categoriasDelCliente: updateObject(state.categoriasDelCliente, {
      loading: false,
      error: error,
      estaCargado: true,
    }),
  });
};
//#endregion

//#region load evolucion de ventas
const loadDeudaPorMesStart = (state) => {
  return updateObject(state, {
    deudasPorMes: updateObject(state.deudasPorMes, {
      deudas: [],
      deudasDataTable: [],
      totalDeudas: 0,
      loading: true,
      estaCargado: false,
      error: null,
    }),
  });
};

const loadDeudaPorMesSuccess = (
  state,
  deudas,
  deudasDataTable,
  totalDeudas
) => {
  return updateObject(state, {
    deudasPorMes: updateObject(state.deudasPorMes, {
      deudas,
      deudasDataTable,
      totalDeudas,
      loading: false,
      estaCargado: true,
    }),
  });
};

const loadDeudaPorMesFail = (state, error) => {
  return updateObject(state, {
    deudasPorMes: updateObject(state.deudasPorMes, {
      loading: false,
      error: error,
      estaCargado: true,
    }),
  });
};

const clearDeudaPorMes = (state) => {
  return updateObject(state, {
    deudasPorMes: updateObject(state.deudasPorMes, {
      deudas: [],
      deudasDataTable: [],
      totalDeudas: 0,
      loading: false,
      estaCargado: false,
      error: null,
    }),
  });
};
//#endregion

//#region load deudas del mes por ordenamiento



const loadDeudaDelMesPorOrdenamientoStart = (state, ordenamiento) => {
  const keyOrd = keyOrdenamiento(ordenamiento);

  return updateObject(state, {
    [keyOrd]: updateObject(state[keyOrd], {
      utilidades: [],
      utilidadesNegativo: [],
      total: 0,
      loading: true,
      estaCargado: false,
      error: null,
      fecha: "",
    }),
  });
};

const loadDeudaDelMesPorOrdenamientoSuccess = (
  state,
  deudas,
  deudasNegativo,
  deudasDataTable,
  total,
  fecha,
  ordenamiento
) => {
  const keyOrd = keyOrdenamiento(ordenamiento);
  return updateObject(state, {
    [keyOrd]: updateObject(state[keyOrd], {
      deudas,
      deudasNegativo,
      deudasDataTable,
      total,
      loading: false,
      estaCargado: true,
      fecha,
    }),
  });
};

const loadDeudaDelMesPorOrdenamientoFail = (state, error, ordenamiento) => {
  const keyOrd = keyOrdenamiento(ordenamiento);

  return updateObject(state, {
    [keyOrd]: updateObject(state[keyOrd], {
      error,
      loading: false,
      estaCargado: true,
    }),
  });
};

const clearDeudaDelMesPorOrdenamiento = (state, ordenamiento) => {
  const keyOrd = keyOrdenamiento(ordenamiento);

  return updateObject(state, {
    [keyOrd]: updateObject(state[keyOrd], initialState[keyOrd]),
  });
};
//#endregion

//#region loadPedidosPendientes
const loadPedidosPendientesStart = (state) => {
  return updateObject(state, {
    pedidosPendientes: updateObject(state.pedidosPendientes, {
      loading: true,
    }),
  });
};

const loadPedidosPendientesSuccess = (state, cantidad, total, prom30Dias) => {
  return updateObject(state, {
    pedidosPendientes: updateObject(state.pedidosPendientes, {
      cantidad,
      total,
      prom30Dias,
      loading: false,
      estaCargado: true,
      error: null,
    }),
  });
};

const loadPedidosPendientesFail = (state, error) => {
  return updateObject(state, {
    pedidosPendientes: updateObject(state.pedidosPendientes, {
      error,
      loading: false,
      loadingPorCliente: false,
    }),
  });
};

const clearPedidosPendientes = (state) => {
  return updateObject(state, {
    pedidosPendientes: updateObject(state.pedidosPendientes, {
      cantidad: 0,
      total: 0,
      prom30Dias: 0,
      loading: false,
      error: null,
      estaCargado: false,
      cantidadPorCliente: 0,
      totalPorCliente: 0,
      prom30DiasPorCliente: 0,
      loadingPorCliente: false,
    }),
  });
};
//#endregion

//#region loadPedidosMesComparativo
const loadPedidosMesComparativoStart = (state) => {
  return updateObject(state, {
    pedidosMesComparativo: updateObject(state.pedidosMesComparativo, {
      loading: true,
    }),
  });
};

const loadPedidosMesComparativoSuccess = ( state, transMesAnt, totalMesAnt, transMesAct, totalMesAct, porcTrans, porcTotal
) => {
  return updateObject(state, {
    pedidosMesComparativo: updateObject(state.pedidosMesComparativo, {
      transMesAnt,
      totalMesAnt,
      transMesAct,
      totalMesAct,
      porcTrans,
      porcTotal,
      loading: false,
      estaCargado: true,
      error: null,
    }),
  });
};

const loadPedidosMesComparativoFail = (state, error) => {
  return updateObject(state, {
    pedidosMesComparativo: updateObject(state.pedidosMesComparativo, {
      error,
      loading: false,
      loadingPorCliente: false,
    }),
  });
};

const clearPedidosMesComparativo = (state) => {
  return updateObject(state, {
    pedidosMesComparativo: updateObject(state.pedidosMesComparativo, {
      transMesAnt: 0,
      totalMesAnt: 0,
      transMesAct: 0,
      totalMesAct: 0,
      porcTrans: 0,
      porcTotal: 0,
      loading: false,
      error: null,
      estaCargado: false,
    }),
  });
};
//#endregion

//#region loadEstadosPedidosPendientes
const loadEstadosPedidosPendientesStart = (state) => {
  return updateObject(state, {
    estadosPedidosPendientes: updateObject(state.estadosPedidosPendientes, {
      loading: true,
    }),
  });
};

const loadEstadosPedidosPendientesSuccess = (state, pedidos, total) => {
  return updateObject(state, {
    estadosPedidosPendientes: updateObject(state.estadosPedidosPendientes, {
      pedidos,
      loading: false,
      estaCargado: true,
      total,
      error: null,
    }),
  });
};

const loadEstadosPedidosPendientesFail = (state, error) => {
  return updateObject(state, {
    estadosPedidosPendientes: updateObject(state.estadosPedidosPendientes, {
      error,
      loading: false,
      loadingPorCliente: false,
    }),
  });
};

const clearEstadosPedidosPendientes = (state) => {
  return updateObject(state, {
    estadosPedidosPendientes: updateObject(state.estadosPedidosPendientes, {
      pedidos: [],
      loading: false,
      error: null,
      estaCargado: false,
      total: 0,
    }),
  });
};
//#endregion


//#region loadVentasPorVendedor
const loadVentasPorVendedorStart = (state) => {
  return updateObject(state, {
    ventasPorVendedor: updateObject(state.ventasPorVendedor, {
      loading: true,
    }),
  });
};

const loadVentasPorVendedorSuccess = (state, unidadesVendidas, ventas, cantidad) => {
  return updateObject(state, {
    ventasPorVendedor: updateObject(state.ventasPorVendedor, {
      unidadesVendidas,
      ventas,
      loading: false,
      cantidad,
      estaCargado: true,
      error: null,
    }),
  });
};

const loadVentasPorVendedorFail = (state, error) => {
  return updateObject(state, {
    ventasPorVendedor: updateObject(state.ventasPorVendedor, {
      error,
      loading: false,
      loadingPorCliente: false,
    }),
  });
};

const clearVentasPorVendedor = (state) => {
  return updateObject(state, {
    ventasPorVendedor: updateObject(state.ventasPorVendedor, {
      unidadesVendidas: 0,
      ventas: 0,
      loading: false,
      error: null,
      estaCargado: false,
      unidadesVendidasPorCliente: 0,
      ventasPorCliente: 0,
      loadingPorCliente: false,
    }),
  });
};
//#endregion
//#region Comprobantes Pedidos Pendientes
const loadComprobantesPedidosPendientesStart = (state) => {
  return updateObject(state, {
    comprobantesPedidosPendientes: updateObject(state.comprobantesPedidosPendientes, {
      loading: true,
    }),
  });
};

const loadComprobantesPedidosPendientesSuccess = (state, comprobantes) => {
  return updateObject(state, {
    comprobantesPedidosPendientes: updateObject(state.comprobantesPedidosPendientes, {
      comprobantes,
      loading: false,
      estaCargado: true,
      error: null,
    }),
  });
};

const loadComprobantesPedidosPendientesFail = (state, error) => {
  return updateObject(state, {
    comprobantesPedidosPendientes: updateObject(state.comprobantesPedidosPendientes, {
      loading: false,
      estaCargado: false,
      error,
    }),
  });
};

const clearComprobantesPedidosPendientes = (state) => {
  return updateObject(state, {
    comprobantesPedidosPendientes: updateObject(state.comprobantesPedidosPendientes, {
      comprobantes: [],
      loading: false,
      estaCargado: false,
      error: null,
    }),
  });
};
//#endregion

//#region loadVentasMesComparativo
const loadVentasMesComparativoStart = (state) => {
  return updateObject(state, {
    ventasMesComparativo: updateObject(state.ventasMesComparativo, {
      loading: true,
    }),
  });
};

const loadVentasMesComparativoSuccess = (state, montoMesActual, montoMesAnterior) => {
  return updateObject(state, {
    ventasMesComparativo: updateObject(state.ventasMesComparativo, {
      montoMesActual,
      montoMesAnterior,
      loading: false,
      estaCargado: true,
      error: null,
    }),
  });
};

const loadVentasMesComparativoFail = (state, error) => {
  return updateObject(state, {
    ventasMesComparativo: updateObject(state.ventasMesComparativo, {
      loading: false,
      loadingPorCliente: false,
      error,
    }),
  });
};

const clearVentasMesComparativo = (state) => {
  return updateObject(state, {
    ventasMesComparativo: updateObject(state.ventasMesComparativo, {
      montoMesActual: 0,
      montoMesAnterior: 0,
      loading: false,
      estaCargado: false,
      error: null,
    }),
  });
};
//#endregion

//#region loadFacturasPendientes
const loadFacturasPendientesStart = (state) => {
  return updateObject(state, {
    facturasPendientes: updateObject(state.facturasPendientes, {
      loading: true,
    }),
  });
};

const loadFacturasPendientesSuccess = (state, facturas, totalFacturas) => {
  return updateObject(state, {
    facturasPendientes: updateObject(state.facturasPendientes, {
      facturas,
      totalFacturas,
      loading: false,
      estaCargado: true,
      error: null,
    }),
  });
};

const loadFacturasPendientesFail = (state, error) => {
  return updateObject(state, {
    facturasPendientes: updateObject(state.facturasPendientes, {
      loading: false,
      error,
    }),
  });
};

const clearFacturasPendientes = (state) => {
  return updateObject(state, {
    facturasPendientes: updateObject(state.facturasPendientes, {
      facturas: [],
      totalFacturas: 0,
      loading: false,
      estaCargado: false,
      error: null,
    }),
  });
};
//#endregion

//#region Comprobantes Facturas Pendientes
const loadComprobantesFacturasPendientesStart = (state) => {
  return updateObject(state, {
    comprobantesFacturasPendientes: updateObject(state.comprobantesFacturasPendientes, {
      loading: true,
    }),
  });
};

const loadComprobantesFacturasPendientesSuccess = (state, comprobantes) => {
  return updateObject(state, {
    comprobantesFacturasPendientes: updateObject(state.comprobantesFacturasPendientes, {
      comprobantes,
      loading: false,
      estaCargado: true,
      error: null,
    }),
  });
};

const loadComprobantesFacturasPendientesFail = (state, error) => {
  return updateObject(state, {
    comprobantesFacturasPendientes: updateObject(state.comprobantesFacturasPendientes, {
      loading: false,
      error,
    }),
  });
};

const clearComprobantesFacturasPendientes = (state) => {
  return updateObject(state, {
    comprobantesFacturasPendientes: updateObject(state.comprobantesFacturasPendientes, {
      comprobantes: [],
      loading: false,
      estaCargado: false,
      error: null,
    }),
  });
};

//#endregion

//#region load apertura deuda por ordenamiento
const keyAperturaDeudaOrdenamiento = (ordenamiento) => {
  switch (ordenamiento) {
    case 0:
      return "aperturaDeudaPorCategoriaDeCliente";

    case 1:
      return "aperturaDeudaPorVendedor";

    default:
      break;
  }
};

const loadAperturaDeudaPorOrdenamientoStart = (state, id, ordenamiento) => {
  const keyOrd = keyAperturaDeudaOrdenamiento(ordenamiento);
  const item = {
    id,
    ventasLocal: [],
    loading: true,
    estaCargado: false,
    error: null,
  };

  const items = [...state[keyOrd].items, item];

  return updateObject(state, {
    [keyOrd]: updateObject(state[keyOrd], {
      items,
    }),
  });
};

const loadAperturaDeudaPorOrdenamientoSuccess = (state, id, ventasLocal, ordenamiento) => {
  const keyOrd = keyAperturaDeudaOrdenamiento(ordenamiento);
  const item = {
    id,
    ventasLocal,
    loading: false,
    estaCargado: true,
    error: null,
  };
  const filtered = state[keyOrd].items.filter((i) => i.id !== id);
  const items = [...filtered, item];

  return updateObject(state, {
    [keyOrd]: updateObject(state[keyOrd], {
      items,
    }),
  });
};

const loadAperturaDeudaPorOrdenamientoFail = (state, id, error, ordenamiento) => {
  const keyOrd = keyAperturaDeudaOrdenamiento(ordenamiento);
  const item = {
    id,
    ventasLocal: [],
    loading: false,
    estaCargado: true,
    error,
  };
  const items = [...filtered, item];

  return updateObject(state, {
    [keyOrd]: updateObject(state[keyOrd], {
      items,
    }),
  });
};

const clearAperturaDeudaPorOrdenamiento = (state, ordenamiento) => {
  const keyOrd = keyAperturaDeudaOrdenamiento(ordenamiento);

  return updateObject(state, {
    [keyOrd]: updateObject(state[keyOrd], initialState[keyOrd]),
  });
};
//#endregion

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SELECCION_FECHA_DEUDAS_DEL_MES_ANIO:
      return seleccionFechaDeudasDelMesAnio(state, action.mes, action.anio);

    case actionTypes.SELECCION_DATA_DEUDA_DEL_MES_POR:
      return {
        ...state,
        ordenamientoDeudaDelMesPorSelected: action.ordenamientoSelected,
      };

    case actionTypes.LOAD_COMPROBANTES_VENTAS_START:
      return loadComprobantesVentasStart(state);
    case actionTypes.LOAD_COMPROBANTES_VENTAS_SUCCESS:
      return loadComprobantesVentasSuccess(state, action.comprobantesVentas);
    case actionTypes.LOAD_COMPROBANTES_VENTAS_FAIL:
      return loadComprobantesVentasFail(state, action.error);

    case actionTypes.LOAD_COMPROBANTES_COBRANZAS_START:
      return loadComprobantesCobranzasStart(state);
    case actionTypes.LOAD_COMPROBANTES_COBRANZAS_SUCCESS:
      return loadComprobantesCobranzasSuccess(
        state,
        action.comprobantesCobranzas
      );
    case actionTypes.LOAD_COMPROBANTES_COBRANZAS_FAIL:
      return loadComprobantesCobranzasFail(state, action.error);

    case actionTypes.LOAD_CATEGORIAS_DEL_CLIENTE_START:
      return loadCategoriasDelClienteStart(state);
    case actionTypes.LOAD_CATEGORIAS_DEL_CLIENTE_SUCCESS:
      return loadCategoriasDelClienteSuccess(state, action.categorias);
    case actionTypes.LOAD_CATEGORIAS_DEL_CLIENTE_FAIL:
      return loadCategoriasDelClienteFail(state, action.error);

    case actionTypes.LOAD_DEUDA_POR_MES_START:
      return loadDeudaPorMesStart(state);
    case actionTypes.LOAD_DEUDA_POR_MES_SUCCESS:
      return loadDeudaPorMesSuccess(
        state,
        action.deudas,
        action.deudasDataTable,
        action.totalDeudas
      );
    case actionTypes.LOAD_DEUDA_POR_MES_FAIL:
      return loadDeudaPorMesFail(state, action.error);
    case actionTypes.CLEAR_DEUDA_POR_MES:
      return clearDeudaPorMes(state);

    case actionTypes.LOAD_DEUDA_DEL_MES_POR_ORDENAMIENTO_START:
      return loadDeudaDelMesPorOrdenamientoStart(state, action.ordenamiento);
    case actionTypes.LOAD_DEUDA_DEL_MES_POR_ORDENAMIENTO_SUCCESS:
      return loadDeudaDelMesPorOrdenamientoSuccess(
        state,
        action.deudas,
        action.deudasNegativo,
        action.deudasDataTable,
        action.totalDeudas,
        action.fecha,
        action.ordenamiento
      );
    case actionTypes.LOAD_DEUDA_DEL_MES_POR_ORDENAMIENTO_FAIL:
      return loadDeudaDelMesPorOrdenamientoFail(
        state,
        action.error,
        action.ordenamiento
      );
    case actionTypes.CLEAR_DEUDA_DEL_MES_POR_ORDENAMIENTO:
      return clearDeudaDelMesPorOrdenamiento(state, action.ordenamiento);
    
    case actionTypes.LOAD_APERTURA_DE_DEUDA_POR_ORDENAMIENTO_START:
      return loadAperturaDeudaPorOrdenamientoStart(state, action.id, action.ordenamiento);
    case actionTypes.LOAD_APERTURA_DE_DEUDA_POR_ORDENAMIENTO_SUCCESS:
      return loadAperturaDeudaPorOrdenamientoSuccess(state, action.id, action.ventasLocal, action.ordenamiento);
    case actionTypes.LOAD_APERTURA_DE_DEUDA_POR_ORDENAMIENTO_FAIL:
      return loadAperturaDeudaPorOrdenamientoFail(state, action.id, action.error, action.ordenamiento);
    case actionTypes.CLEAR_APERTURA_DE_DEUDA_POR_ORDENAMIENTO:
      return clearAperturaDeudaPorOrdenamiento(state, action.id, action.ordenamiento);
    
    case actionTypes.LOAD_PEDIDOS_PENDIENTES_START:
      return loadPedidosPendientesStart(state);
    case actionTypes.LOAD_PEDIDOS_PENDIENTES_SUCCESS:
      return loadPedidosPendientesSuccess(state, action.cantidad, action.total, action.prom30Dias);
    case actionTypes.LOAD_PEDIDOS_PENDIENTES_FAIL:
      return loadPedidosPendientesFail(state, action.error);
    case actionTypes.CLEAR_PEDIDOS_PENDIENTES:
      return clearPedidosPendientes(state);

    case actionTypes.LOAD_PEDIDOS_MES_COMPARATIVO_START:
      return loadPedidosMesComparativoStart(state);
    case actionTypes.LOAD_PEDIDOS_MES_COMPARATIVO_SUCCESS:
      return loadPedidosMesComparativoSuccess(state, action.transMesAnt, action.totalMesAnt,
        action.transMesAct, action.totalMesAct, action.porcTrans, action.porcTotal
      );
    case actionTypes.LOAD_PEDIDOS_MES_COMPARATIVO_FAIL:
      return loadPedidosMesComparativoFail(state, action.error);
    case actionTypes.CLEAR_PEDIDOS_MES_COMPARATIVO:
      return clearPedidosMesComparativo(state);

    case actionTypes.LOAD_ESTADOS_PEDIDOS_PENDIENTES_START:
      return loadEstadosPedidosPendientesStart(state);
    case actionTypes.LOAD_ESTADOS_PEDIDOS_PENDIENTES_SUCCESS:
      return loadEstadosPedidosPendientesSuccess(state, action.estadosPedidosPendientes, action.total);
    case actionTypes.LOAD_ESTADOS_PEDIDOS_PENDIENTES_FAIL:
      return loadEstadosPedidosPendientesFail(state, action.error);
    case actionTypes.CLEAR_ESTADOS_PEDIDOS_PENDIENTES:
      return clearEstadosPedidosPendientes(state);

    case actionTypes.LOAD_VENTAS_POR_VENDEDOR_START:
      return loadVentasPorVendedorStart(state);
    case actionTypes.LOAD_VENTAS_POR_VENDEDOR_SUCCESS:
      return loadVentasPorVendedorSuccess(state, action.unidadesVendidas, action.ventas, action.cantidad);
    case actionTypes.LOAD_VENTAS_POR_VENDEDOR_FAIL:
      return loadVentasPorVendedorFail(state, action.error);
    case actionTypes.CLEAR_VENTAS_POR_VENDEDOR:
      return clearVentasPorVendedor(state);

    case actionTypes.LOAD_COMPROBANTES_PEDIDOS_PENDIENTES_START:
      return loadComprobantesPedidosPendientesStart(state);
    case actionTypes.LOAD_COMPROBANTES_PEDIDOS_PENDIENTES_SUCCESS:
      return loadComprobantesPedidosPendientesSuccess(state, action.comprobantes);
    case actionTypes.LOAD_COMPROBANTES_PEDIDOS_PENDIENTES_FAIL:
      return loadComprobantesPedidosPendientesFail(state, action.error);
    case actionTypes.CLEAR_COMPROBANTES_PEDIDOS_PENDIENTES:
      return clearComprobantesPedidosPendientes(state);

    case actionTypes.LOAD_VENTAS_MES_COMPARATIVO_START:
      return loadVentasMesComparativoStart(state);
    case actionTypes.LOAD_VENTAS_MES_COMPARATIVO_SUCCESS:
      return loadVentasMesComparativoSuccess(state, action.montoMesActual, action.montoMesAnterior);
    case actionTypes.LOAD_VENTAS_MES_COMPARATIVO_FAIL:
      return loadVentasMesComparativoFail(state, action.error);
    case actionTypes.CLEAR_VENTAS_MES_COMPARATIVO:
      return clearVentasMesComparativo(state);

    case actionTypes.LOAD_FACTURAS_PENDIENTES_START:
      return loadFacturasPendientesStart(state);
    case actionTypes.LOAD_FACTURAS_PENDIENTES_SUCCESS:
      return loadFacturasPendientesSuccess(state, action.facturas, action.totalFacturas);
    case actionTypes.LOAD_FACTURAS_PENDIENTES_FAIL:
      return loadFacturasPendientesFail(state, action.error);
    case actionTypes.CLEAR_FACTURAS_PENDIENTES:
      return clearFacturasPendientes(state);

    case actionTypes.LOAD_COMPROBANTES_FACTURAS_PENDIENTES_START:
      return loadComprobantesFacturasPendientesStart(state);
    case actionTypes.LOAD_COMPROBANTES_FACTURAS_PENDIENTES_SUCCESS:
      return loadComprobantesFacturasPendientesSuccess(state, action.comprobantes);
    case actionTypes.LOAD_COMPROBANTES_FACTURAS_PENDIENTES_FAIL:
      return loadComprobantesFacturasPendientesFail(state, action.error);
    case actionTypes.CLEAR_COMPROBANTES_FACTURAS_PENDIENTES:
      return clearComprobantesFacturasPendientes(state);

    default:
      return state;
  }
};

export default reducer;
