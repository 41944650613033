// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Loading__Loading__Ywclv{\r\n    display: flex;\r\n    align-items:center;\r\n    justify-content: center;\r\n    -webkit-animation: Loading__opacityLoading__omB2n 1.5s infinite;\r\n            animation: Loading__opacityLoading__omB2n 1.5s infinite;\r\n}\r\n\r\n@-webkit-keyframes Loading__opacityLoading__omB2n{\r\n    0%, 100%{\r\n        opacity: 1;\r\n    }\r\n    50%{\r\n        opacity: 0.3;\r\n    }\r\n}\r\n\r\n@keyframes Loading__opacityLoading__omB2n{\r\n    0%, 100%{\r\n        opacity: 1;\r\n    }\r\n    50%{\r\n        opacity: 0.3;\r\n    }\r\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Loading": "Loading__Loading__Ywclv",
	"opacityLoading": "Loading__opacityLoading__omB2n"
};
export default ___CSS_LOADER_EXPORT___;
