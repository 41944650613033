// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CierreTarea__CierreTarea__1XQrC{\r\n    display: flex; \r\n    justify-content: space-around;\r\n}\r\n\r\n.CierreTarea__FechaCierre__3-eoK{\r\n    display: flex; \r\n    flex-direction: row;\r\n    justify-content: space-between;\r\n}\r\n\r\n.CierreTarea__Header__3EzYE{\r\n    font-size: 14px;\r\n    padding-top: 0px;\r\n    padding-bottom: 0px;\r\n    background-color: #fafafa;\r\n}\r\n\r\n.CierreTarea__Cell__1ENqU{\r\n    font-size: 12px;\r\n    padding-bottom: 5px;\r\n    padding-top: 5px;\r\n    height: 49px;\r\n}\r\n\r\n.CierreTarea__Root__2Gp1R{\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-content: center;\r\n    width: 100%;\r\n    height: 50%;\r\n    background-color: white;\r\n    padding-top: 5px;\r\n}\r\n\r\n.CierreTarea__Table__374h1{\r\n    display: flex;\r\n    justify-content: center;\r\n    width: 100%;\r\n    height: 50%;\r\n}\r\n\r\n.CierreTarea__TableBody__32sU2{\r\n    width: 100%;\r\n    border-radius: 0px;\r\n    box-shadow: none;\r\n}\r\n\r\n.CierreTarea__Grabar__1PP_v{\r\n    display: flex;\r\n    flex-direction: row;\r\n    justify-content: flex-end;\r\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CierreTarea": "CierreTarea__CierreTarea__1XQrC",
	"FechaCierre": "CierreTarea__FechaCierre__3-eoK",
	"Header": "CierreTarea__Header__3EzYE",
	"Cell": "CierreTarea__Cell__1ENqU",
	"Root": "CierreTarea__Root__2Gp1R",
	"Table": "CierreTarea__Table__374h1",
	"TableBody": "CierreTarea__TableBody__32sU2",
	"Grabar": "CierreTarea__Grabar__1PP_v"
};
export default ___CSS_LOADER_EXPORT___;
