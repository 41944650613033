import { Checkbox, FormControlLabel, Typography } from "@material-ui/core";
import { Fragment } from "react";
import moment from "moment";
import classes from "./DetalleTrabajo.css";
import Button from "../../UI/Button/Button";

const DetalleTrabajo = ({
  info,
  loading,
  idSucursal,
  idSistema,
  idComproba,
  nroReferencia,
  onDescargarArchivo,
}) => {
  const TipoPinchazo = (pinchazo) => {
    if (pinchazo && pinchazo === 1) return "En movimiento";
    else return "Estático";
  };

  const MaterialPropio = (propio) => {
    if (propio) return "Propio ";
    else return "Cliente";
  };

  const estiloUrgencia =
    info && info.urgencia
      ? {
          alignItems: "start",
          fontSize: "16px",
          width: "25%",
          backgroundColor: "#F24444",
          height: "23px",
          borderRadius: "5px",
        }
      : {
          alignItems: "start",
          fontSize: "16px",
          width: "25%",
          height: "23px",
          borderRadius: "5px",
        };

  return (
    <Fragment>
      <div className={classes.DetalleTrabajo}>
        <div className={classes.ClienteProductoMaquina}>
          <div className={classes.ClienteCotizacion}>
            <div className={classes.Cliente}>
              <Typography
                style={{
                  marginRight: "20px",
                  marginBottom: "15px",
                  paddingLeft: "5px",
                  fontSize: "22px",
                  color: "#0D6973",
                }}
              >
                <span style={{ fontWeight: "bold" }}>Cliente: </span>{" "}
                {!loading && info && `${info.nombreCliente}`}
              </Typography>
            </div>

            <div className={classes.Cotizacion}>
              <Typography className={classes.TypographyTitle}>
                <span style={{ fontWeight: "bold" }}>
                  Nro. Orden de compra:{" "}
                </span>{" "}
                {info && `${info.nroOrdenDeCompra}`}
              </Typography>

              <Typography className={classes.TypographyTitle}>
                <span style={{ fontWeight: "bold" }}>Nro. Cotización: </span>{" "}
                {info && `${info.nroCotizacion}`}
              </Typography>
            </div>

            <div className={classes.OrdenDeTrabajo}>
              <Typography className={classes.TypographyTitle}>
                <span style={{ fontWeight: "bold" }}>
                  Nro. Orden de trabajo:{" "}
                </span>{" "}
                {info && `${info.idTrabajo}`}
              </Typography>

              <Typography className={classes.TypographyTitle}>
                <span style={{ fontWeight: "bold" }}>Nro. Programa: </span>
                {info && `${info.nroPrograma}`}
              </Typography>
            </div>

            <div className={classes.Estado}>
              <Typography className={classes.TypographyTitle}>
                <span style={{ fontWeight: "bold" }}>Estado: </span>
                {info && info.proceso}
              </Typography>
            </div>
          </div>

          <div
            className={classes.BlockBorder}
            style={{ backgroundColor: "#04ADBF" }}
          >
            <Typography
              style={{
                marginRight: "20px",
                marginBottom: "5px",
                fontSize: "16px",
                color: "white",
              }}
              align="left"
            >
              <span style={{ fontWeight: "bold" }}>
                Descripción del producto
              </span>{" "}
            </Typography>

            <Typography
              style={{
                marginRight: "20px",
                marginBottom: "5px",
                fontSize: "16px",
                color: "white",
              }}
              align="left"
            >
              <span style={{ fontWeight: "bold" }}>Detalle: </span>{" "}
              {info && `${info.producto}`}
            </Typography>

            <div className={classes.TypographyBlock}>
              <Typography
                style={{
                  marginBottom: "5px",
                  fontSize: "16px",
                  width: "25%",
                  color: "white",
                }}
                align="left"
              >
                <span style={{ fontWeight: "bold" }}>Tiempo unitario: </span>{" "}
                {info && info.tiempoUnitario}
              </Typography>
              
              <Typography
                style={{
                  marginBottom: "5px",
                  fontSize: "16px",
                  width: "25%",
                  color: "white",
                }}
                align="left"
              >
                <span style={{ fontWeight: "bold" }}>Tiempo total: </span>{" "}
                {info && info.tiempoUnitario * info.cantidad}
              </Typography>

              <Typography
                style={{
                  marginBottom: "5px",
                  fontSize: "16px",
                  width: "25%",
                  color: "white",
                }}
                alignRight
              >
                <span style={{ fontWeight: "bold" }}>Espesor: </span>{" "}
                {info && info.espesor}
              </Typography>

              <Typography
                style={{
                  marginBottom: "5px",
                  fontSize: "16px",
                  width: "25%",
                  color: "white",
                }}
                align="left"
              >
                <span style={{ fontWeight: "bold" }}>Calidad: </span>{" "}
                {info && `${info.idMaterial}`}
              </Typography>
            </div>

            <div className={classes.TypographyBlock}>
              <Typography
                style={{
                  marginBottom: "5px",
                  fontSize: "16px",
                  width: "25%",
                  color: "white",
                }}
                align="left"
              >
                <span style={{ fontWeight: "bold" }}>Cantidad total: </span>{" "}
                {info && info.cantidad}
              </Typography>

              <Typography
                style={{
                  marginBottom: "5px",
                  fontSize: "16px",
                  width: "25%",
                  color: "white",
                }}
                align="left"
              >
                <span style={{ fontWeight: "bold" }}>Cantidad pendiente: </span>{" "}
                {info && info.cantidadPendiente}
              </Typography>

              <Typography
                style={{
                  marginBottom: "5px",
                  fontSize: "16px",
                  width: "25%",
                  color: "white",
                }}
                align="left"
              >
                <span style={{ fontWeight: "bold" }}>
                  Cantidad a producir:{" "}
                </span>{" "}
                {info && info.cantidadPendiente}
              </Typography>

              <Typography
                style={{
                  marginBottom: "5px",
                  fontSize: "16px",
                  width: "25%",
                  color: "white",
                }}
                align="left"
              >
                <span style={{ fontWeight: "bold" }}>Material: </span>{" "}
                {info && info.propio} {MaterialPropio(info.propio)}
              </Typography>
            </div>

            <div
              className={classes.TypographyBlock}
              style={{ marginBottom: "5px" }}
            >
              <Typography
                style={{
                  marginBottom: "5px",
                  fontSize: "16px",
                  width: "25%",
                  color: "white",
                }}
                align="left"
              >
                <span style={{ fontWeight: "bold" }}>Recorte: </span>
                {info && `${info.recorte} x ${info.xRecorte}`}
              </Typography>

              <Typography
                style={{
                  marginBottom: "5px",
                  fontSize: "16px",
                  width: "25%",
                  color: "white",
                }}
                align="left"
              >
                <span style={{ fontWeight: "bold" }}>Pieza por aprov: </span>{" "}
                {info && `${info.aprovechamientoPorPieza}`}
              </Typography>

              <Typography
                style={{
                  marginBottom: "5px",
                  fontSize: "16px",
                  width: "25%",
                  color: "white",
                }}
                align="left"
              >
                <span style={{ fontWeight: "bold" }}>Cantidad: </span>{" "}
                {info && `${info.cantidadAprovechable}`}
              </Typography>
            </div>
          </div>

          <div
            className={classes.BlockBorder}
            style={{ backgroundColor: "#0D6973" }}
          >
            <Typography
              style={{
                marginRight: "23px",
                marginBottom: "5px",
                fontSize: "16px",
                color: "white",
              }}
              align="left"
            >
              <span style={{ fontWeight: "bold" }}>Asignaciones </span>
            </Typography>

            <div className={classes.TypographyBlock}>
              <Typography
                style={{
                  marginBottom: "5px",
                  fontSize: "16px",
                  width: "25%",
                  color: "white",
                }}
                align="left"
              >
                <span style={{ fontWeight: "bold" }}>Máquina: </span>{" "}
                {info && info.nombreMaquina}
              </Typography>

              <Typography
                style={{
                  marginBottom: "5px",
                  fontSize: "16px",
                  width: "25%",
                  color: "white",
                  paddingLeft: "5px",
                }}
                align="left"
              >
                <span style={{ fontWeight: "bold" }}>Ingreso: </span>{" "}
                {info && `${moment(info.fechaIngreso).format("DD/MM/YYYY")}`}
              </Typography>

              <FormControlLabel
                style={{ alignItems: "start", width: "25%" }}
                control={
                  <Checkbox
                    checked={info && info.embalaje}
                    color={"#FFFFFF"}
                    style={{ paddingTop: "0px" }}
                  />
                }
                label={
                  <Typography
                    style={{
                      alignItems: "start",
                      fontSize: "16px",
                      color: "white",
                    }}
                  >
                    Lleva embalaje
                  </Typography>
                }
              />
              <Button
                disabled={!info.tieneOrdenDeCompra}
                onClick={() =>
                  onDescargarArchivo(
                    idSucursal,
                    idSistema,
                    idComproba,
                    nroReferencia,
                    info.idArticulo,
                    info.renglon,
                    0
                  )
                }
              >
                Ver Órden de Compra
              </Button>
            </div>

            <div className={classes.TypographyBlock}>
              <Typography
                style={{
                  marginBottom: "5px",
                  fontSize: "16px",
                  width: "25%",
                  color: "white",
                }}
                align="left"
              >
                <span style={{ fontWeight: "bold" }}>Velocidad de corte: </span>{" "}
                {info && info.velocidadCorte}
              </Typography>
              <div
                style={{
                  marginBottom: "5px",
                  width: "25%",
                  color: "white",
                }}
              >
                <Typography
                  style={{
                    fontSize: "16px",
                    width: "65%",
                    color: "white",
                    backgroundColor: "#F24444",
                    paddingLeft: "5px",
                    borderRadius: "5px",
                  }}
                  align="left"
                >
                  <span style={{ fontWeight: "bold" }}>Entrega: </span>{" "}
                  {info && `${moment(info.fechaEntrega).format("DD/MM/YYYY")}`}
                </Typography>
              </div>

              <FormControlLabel
                style={estiloUrgencia}
                control={
                  <Checkbox
                    checked={info && info.urgencia}
                    color={"#F15A29"}
                    style={{ paddingTop: "0px" }}
                  />
                }
                label={
                  <Typography
                    style={{
                      alignItems: "start",
                      fontSize: "16px",
                      color: "white",
                    }}
                  >
                    Urgencia
                  </Typography>
                }
              />
              <Button
                disabled={!info.tienePlano}
                onClick={() =>
                  onDescargarArchivo(
                    idSucursal,
                    idSistema,
                    idComproba,
                    nroReferencia,
                    info.idArticulo,
                    info.renglon,
                    1
                  )
                }
              >
                Ver Plano
              </Button>
            </div>

            <div className={classes.TypographyBlock}>
              <Typography
                style={{
                  marginBottom: "5px",
                  fontSize: "16px",
                  width: "25%",
                  color: "white",
                }}
                align="left"
              >
                <span style={{ fontWeight: "bold" }}>Pinchazo: </span>
                {info && TipoPinchazo(info.pinchazo)}
              </Typography>

              <FormControlLabel
                style={{
                  alignItems: "start",
                  fontSize: "14px",
                  width: "25%",
                }}
                control={
                  <Checkbox
                    checked={info && info.controlDeEsquina}
                    color={"#F15A29"}
                    style={{ paddingTop: "0px" }}
                  />
                }
                label={
                  <Typography
                    style={{
                      alignItems: "start",
                      fontSize: "16px",
                      color: "white",
                    }}
                  >
                    Control de corte de esquina
                  </Typography>
                }
              />

              <FormControlLabel
                style={{
                  alignItems: "start",
                  fontSize: "16px",
                  width: "25%",
                }}
                control={
                  <Checkbox
                    checked={info && info.etiqueta}
                    color={"#F15A29"}
                    style={{ paddingTop: "0px" }}
                  />
                }
                label={
                  <Typography
                    style={{
                      alignItems: "start",
                      fontSize: "16px",
                      color: "white",
                    }}
                  >
                    Etiqueta
                  </Typography>
                }
              />
              <Button
                disabled={!info.tieneExcelCotizacion}
                onClick={() =>
                  onDescargarArchivo(
                    idSucursal,
                    idSistema,
                    idComproba,
                    nroReferencia,
                    info.idArticulo,
                    info.renglon,
                    2
                  )
                }
              >
                Ver Excel Cotización
              </Button>
            </div>

            <div className={classes.TypographyBlock}>
              <Typography
                style={{
                  marginBottom: "5px",
                  fontSize: "16px",
                  width: "75%",
                  color: "white",
                }}
                align="left"
              >
                <span style={{ fontWeight: "bold" }}>Comentario: </span>
                {info && info.comentario}
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default DetalleTrabajo;
