// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ErrorGrabacion__Cerrar__nTDxL{\r\n    display: flex;\r\n    justify-content: flex-end;    \r\n}\r\n\r\n.ErrorGrabacion__Container__1KdXY{\r\n    width: 25%\r\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Cerrar": "ErrorGrabacion__Cerrar__nTDxL",
	"Container": "ErrorGrabacion__Container__1KdXY"
};
export default ___CSS_LOADER_EXPORT___;
